import axios from 'axios';

export default {
	name: 'admin-gallery',
	props: ['hash', 'phash', 'data', 'open'],
	data: function () {
		return {
			url: null,
			licence: null,
			comment: null,
			original: null,
			some: 0,
			print: 0,
			tick: 0,
			list: [],
			id: null
		}
	},
	computed: {
		src: function () {
			if (this.url) {
				return this.url;
			} else {
				return '/static/missing.png';
			}
		},
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		click_edit: function (photo, state) {
			photo.edit = state;
			photo.tick++;
			this.tick++;
		},
		updatePhoto: function (file) {
			axios.post(this.$store.state.api + 'admin/photo-update', {
				hash: this.hash,
				licence: this.licence,
				original: this.original,
				comment: this.comment,
				some: this.some ? 1 : 0,
				print: this.print ? 1 : 0,
				priority: 2,
				url: this.$store.state.server + file.link,
				token: this.$store.state.auth
			}).then((response) => {
				if (response.data.code === 200) {
					this.tick++;

					var obj = {
						comment: this.comment,
						url: this.$store.state.server + file.link,
						id: response.data.id,
						removed: false,
						edit: false,
						tick: 0
					};

					this.list.push(obj);
				}
			});
		},
		click_comment: function (photo) {
			axios.post(this.$store.state.api + 'admin/photo-comment', {
				id: photo.id,
				hash: this.hash,
				comment: photo.comment,
				token: this.$store.state.auth
			}).then((response) => {
				photo.id = response.data.id;
				this.click_edit(photo, false);
			});
		},
		click_remove: function (photo) {
			axios.post(this.$store.state.api + 'admin/photo-remove', {
				id: photo.id,
				hash: this.hash,
				token: this.$store.state.auth
			}).then(() => {
				photo.removed = true;
				photo.tick++;
				this.tick++;
			});
		}
	},
	mounted: function () {
		this.data.forEach(item => {
			var obj = {
				comment: item.comment,
				url: item.url,
				id: item.id,
				removed: false,
				edit: false,
				tick: 0
			};

			this.list.push(obj);
		});
	}
};
