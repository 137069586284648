import axios from 'axios';

export default {
	name: 'admin-color',
	props: ['hash', 'phash', 'data', 'open', 'reg'],
	data: function () {
		return {
			color: null,
			value: null,
			tick: 0,
			s: ['00','66','AA','FF']
		}
	},
	computed: {
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		update: function (color) {
			axios.post(this.$store.state.api + 'admin/color-update', {
				hash: this.hash,
				color: color,
				reg: this.reg,
				token: this.$store.state.auth
			}).then(() => {
				this.color = color;
				this.tick++;
			});
		},
		remove: function () {
			axios.post(this.$store.state.api + 'admin/color-remove', {
				hash: this.hash,
				token: this.$store.state.auth
			}).then(() => {
				this.color = null;
				this.tick++;
			});
		}
	},
	mounted: function () {
		if (this.data && this.data[0]) {
			this.color = this.data[0].color;
		}
	}
};
