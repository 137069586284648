import {date, truncate} from '@/common/helpers';

export default {
	name: 'layout-weekly-item',
	props: ['hash'],
	data: function () {
		return {
			enums: [
				{name: 'Akce a události', key: 'events'},
				{name: 'Články a rozhovory', key: 'media'},
				{name: 'Novinky', key: 'news'},
				{name: 'Volební priority', key: 'priority'},
				{name: 'Hodnoty a zásady', key: 'vals'},
				{name: 'Medailonky', key: 'about'},
				{name: 'Slogany', key: 'motto'},
				{name: 'Poradci', key: 'advisors'},
				{name: 'Volební týmy', key: 'team'},
				{name: 'Tým na Hrad', key: 'office'},
				{name: 'Videa a vložená média', key: 'embeds'},
				{name: 'Odkazy', key: 'links'},
				{name: 'Kontakty', key: 'contacts'},
				{name: 'Materiály', key: 'ads'},
				{name: 'Fotky', key: 'photo'},
				{name: 'Loga', key: 'logo'},
				{name: 'Podpora', key: 'support'},
				{name: 'Rozpočet', key: 'budget'},
				{name: 'Průzkumy a odhady', key: 'polls'},
				{name: 'Sázkové kurzy', key: 'bets'},
				{name: 'Odpovědi kandidátů', key: 'topics'},
				{name: 'Ankety v médiích', key: 'questions'},
				// {name: 'Sociální sítě', key: 'social'},
			]
		}
	},
  components: {},
	computed: {
			list: function () {
				return this.$store.getters.pdv('weekly/list');
			},
			detail: function () {
				return this.list ? this.list.data.find(x => x.hash === this.hash) : undefined;
			},
			report: function () {
				if (this.detail) {
					this.$store.dispatch("ga", {title: "Týdeníček: " + this.detail.title });
				}
				return this.detail ? this.$store.getters.pdv('weekly/fetch/' + this.detail.from + '---' + this.detail.to) : undefined;
			},
			sorted: function () {
				if (!this.report) return;

				var list = [];

				this.enums.forEach(en => {
					var obj = {
						name: en.name,
						key: en.key,
						candidates: []
					};

					this.report.data[en.key].forEach(item => {
						var c = obj.candidates.find(x => x.hash === item.hash);

						if (!c) {
							c = {
								hash: item.hash,
								name: this.$$(item.hash),
								person: this.$(item.hash),
								list: []
							}

							obj.candidates.push(c);
						}

						c.list.push(item);
					});

					obj.candidates.sort((a, b) => (a.person ? a.person.csu.main.family : 'zzz').localeCompare((b.person ? b.person.csu.main.family : 'zzz'), 'cs'));

					list.push(obj);
				});

				return list;
			}
	},
  methods: {
		date, truncate,
		$: function (hash) {
			return this.$store.getters.candidate(hash);
		},
		$$: function (hash) {
			var candidate = this.$(hash);
			return candidate ? candidate.csu.main.name + ' ' + candidate.csu.main.family : 'Obecné';
		},
		pj: function (str) {
			var json;

			try {
				json = JSON.parse(str);
			} catch (e) {
				// skip
			}

			return json;
		}
	},
  mounted: function () {
    window.scrollTo(0, 1);
  },
	watch: {
		hash: function () {
			window.scrollTo(0, 1);
		}
	}
};
