import axios from 'axios';
// import {isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'root-poll-list',
	props: [],
	data: function () {
		return {
			form: {},
			sending: false,
			sent: false
		}
	},
	computed: {
		valid: function () {
			return true;
		}
	},
	methods: {
		add: function () {
			if (this.valid) {
				this.sending = true;

				var obj = {
					token: this.$store.state.auth
				}

				Object.keys(this.form).forEach(key => {
					obj[key] = this.form[key];
				});

				axios.post(this.$store.state.api + 'root/polls-new', obj).then((response) => {
					this.sending = false;

					if (response.data.code === 200) {
						this.sent = true;
						setTimeout(() => this.another(), 500);
					}
				});
			}
		},
		another: function () {
			this.form.agency = null;
			this.form.level = 1;
			this.form.type = null;
			this.form.comment = null;
			this.form.from = null;
			this.form.to = null;
			this.form.sum = null;
			this.form.attendance = null;
			this.form.error = null;
			this.form.link = null;
			this.form.published = null;
		}
	},
	mounted: function () {
		this.another();
	}
};
