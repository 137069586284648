import axios from 'axios';
import {isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'admin-links',
	props: ['hash', 'phash', 'data', 'type', 'open', 'senate', 'noRemove'],
	data: function () {
		return {
			list: [],
			url: '',
			label: null,
			typeSelected: null,
			typeSocial: 0,
			sent: false,
			sending: false,
			showHints: false,
			auth: null,
			authShow: false
		}
	},
	computed: {
		valid: function () {
			return isValidHttpUrl(this.url);
		},
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		add: function () {
			if (this.valid) {
				this.sending = true;

				axios.post(this.$store.state.api + 'admin/link-add', {
					hash: this.hash,
					phash: null,
					type: 'program',
					url: encodeURIComponent(this.url),
					label: null,
					token: this.$store.state.auth
				}).then((response) => {
					this.sending = false;
					this.sent = true;

					this.list.push({
						url: this.url,
						type: 'program',
						hash: this.hash,
						phash: null,
						label: null,
						id: response.data.id,
						removed: false
					});

					setTimeout(() => this.another(), 500);
				});
			}
		},
		remove: function (src) {
			axios.post(this.$store.state.api + 'admin/link-remove', {
				hash: this.hash,
				phash: this.phash,
				id: src.id,
				token: this.$store.state.auth
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.find(x => x.id === src.id).removed = true;
				}
			})
		},
		another: function () {
			this.sent = false;
			this.url  = '';
			this.label = '';
			this.typeSelected = 'program';
		}
	},
	mounted: function () {
		if (this.type) {
			this.typeSelected = this.type || 'program';
		}
		if (this.data) {
			this.data.forEach(src => {
				if (src.type === 'program') {
					this.list.push({
						url: src.url,
						type: src.type,
						hash: src.hash,
						phash: null,
						label: null,
						id: src.id,
						removed: false
					});
				}
			})
		}
	}
};
