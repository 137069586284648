import Vue from 'vue';
import Router from 'vue-router';
import LayoutHomepage from '@/layout/homepage-final/do';
import LayoutAbout from '@/layout/about/do';
import LayoutHowTo from '@/layout/how-to/do';
import LayoutNews from '@/layout/news/do';
import LayoutCandidate from '@/layout/candidate/overview/do';
import LayoutCandidateAds from '@/layout/candidate/ads/do';
import LayoutCandidateAnswers from '@/layout/candidate/answers/do';
import LayoutCandidateEvents from '@/layout/candidate/events/do';
import LayoutCandidateGallery from '@/layout/candidate/gallery/do';
import LayoutCandidateMedia from '@/layout/candidate/media/do';
import LayoutCandidateList from '@/layout/candidate/list/do';
import LayoutCandidatePolls from '@/layout/candidate/polls/do';
import LayoutCandidateProgram from '@/layout/candidate/program/do';
import LayoutCandidateProfile from '@/layout/candidate/profile/do';
import LayoutCandidateTeam from '@/layout/candidate/team/do';
import LayoutCandidateTopics from '@/layout/candidate/topics/do';
import LayoutOverviewPolls from '@/layout/overview/polls/do';
import LayoutOverviewBets from '@/layout/overview/bets/do';
import LayoutOverviewEvents from '@/layout/overview/events/do';
import LayoutOverviewTopics from '@/layout/overview/topics/do';
import LayoutQuestionsList from '@/layout/questions/list/do';
import LayoutQuestionsDetail from '@/layout/questions/detail/do';
import LayoutAdmin from '@/admin/main/do';
import LayoutAdminRequest from '@/layout/request/detail/do';
import LayoutWeeklyList from '@/layout/weekly/list/do';
import LayoutWeeklyItem from '@/layout/weekly/item/do';
import LayoutWidgets from '@/layout/widgets/do';
import Layout404 from '@/layout/404/do';
import LayoutQuestionnaire from '@/layout/questionnaire/do';
import LayoutCompare from '@/layout/compare/do';
import LayoutDuel from '@/layout/homepage/do';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'LayoutHomepage',
      component: LayoutHomepage
    },
      {
        path: '/duel',
        name: 'LayoutDuel',
        component: LayoutDuel
      },
    {
      path: '/widgety',
      name: 'LayoutWidgets',
      component: LayoutWidgets
    },
    {
      path: '/anketa',
      name: 'LayoutQuestionnaire',
      component: LayoutQuestionnaire
    },
    {
      path: '/porovnat/:hash',
      name: 'LayoutCompare',
      component: LayoutCompare,
      props: true
    },
    {
      path: '/admin',
      name: 'LayoutAdminStatic',
      component: LayoutAdmin
    },
    {
      path: '/admin/:token',
      name: 'LayoutAdmin',
      component: LayoutAdmin,
      props: true
    },
    {
      path: '/admin-zadost/:id',
      name: 'LayoutAdminRequest',
      component: LayoutAdminRequest,
      props: true
    },
    {
      path: '/o-projektu',
      name: 'LayoutAbout',
      component: LayoutAbout
    },
    {
      path: '/jak-volit',
      name: 'LayoutHowTo',
      component: LayoutHowTo
    },
    {
      path: '/tydenicek',
      name: 'LayoutWeeklyList',
      component: LayoutWeeklyList
    },
    {
      path: '/tydenicek/:hash',
      name: 'LayoutWeeklyItem',
      component: LayoutWeeklyItem,
      props: true
    },
    {
      path: '/volebni-otazky',
      name: 'LayoutQuestionsList',
      component: LayoutQuestionsList
    },
    {
      path: '/volebni-otazky/:hash',
      name: 'LayoutQuestionsDetail',
      component: LayoutQuestionsDetail,
      props: true
    },
    {
      path: '/tyden',
      name: 'LayoutWeeklyListOld',
      component: LayoutWeeklyList
    },
    {
      path: '/tyden/:hash',
      name: 'LayoutWeeklyItemOld',
      component: LayoutWeeklyItem,
      props: true
    },
    {
      path: '/archiv-novinek',
      name: 'LayoutNews',
      component: LayoutNews
    },
    {
      path: '/otazky-a-temata',
      name: 'LayoutOverviewTopics',
      component: LayoutOverviewTopics
    },
    {
      path: '/volebni-pruzkumy',
      name: 'LayoutOverviewPolls',
      component: LayoutOverviewPolls
    },
    {
      path: '/sazkove-kurzy',
      name: 'LayoutOverviewBets',
      component: LayoutOverviewBets
    },
    {
      path: '/akce-udalosti-setkani',
      name: 'LayoutOverviewEvents',
      component: LayoutOverviewEvents
    },
    {
      path: '/kandidat/:hash',
      name: 'LayoutCandidate',
      component: LayoutCandidate,
      props: true
    },
    {
      path: '/kandidati',
      name: 'LayoutCandidateList',
      component: LayoutCandidateList,
    },
    {
      path: '/kandidat/:hash/odpovedi-na-volebni-otazky',
      name: 'LayoutCandidateAnswers',
      component: LayoutCandidateAnswers,
      props: true
    },
    {
      path: '/kandidat/:hash/profil',
      name: 'LayoutCandidateProfile',
      component: LayoutCandidateProfile,
      props: true
    },
    {
      path: '/kandidat/:hash/volebni-materialy',
      name: 'LayoutCandidateAds',
      component: LayoutCandidateAds,
      props: true
    },
    {
      path: '/kandidat/:hash/udalosti-a-setkani',
      name: 'LayoutCandidateEvents',
      component: LayoutCandidateEvents,
      props: true
    },
    {
      path: '/kandidat/:hash/fotogalerie',
      name: 'LayoutCandidateGallery',
      component: LayoutCandidateGallery,
      props: true
    },
    {
      path: '/kandidat/:hash/clanky-a-rozhovory',
      name: 'LayoutCandidateMedia',
      component: LayoutCandidateMedia,
      props: true
    },
    {
      path: '/kandidat/:hash/pruzkumy-sazky-odhady-popularita',
      name: 'LayoutCandidatePolls',
      component: LayoutCandidatePolls,
      props: true
    },
    {
      path: '/kandidat/:hash/program-a-priority',
      name: 'LayoutCandidateProgram',
      component: LayoutCandidateProgram,
      props: true
    },
    {
      path: '/kandidat/:hash/poradci-a-tym',
      name: 'LayoutCandidateTeam',
      component: LayoutCandidateTeam,
      props: true
    },
    {
      path: '/kandidat/:hash/otazky-a-temata',
      name: 'LayoutCandidateTopics',
      component: LayoutCandidateTopics,
      props: true
    },
    {
      path: '/nenalezeno',
      name: 'Layout404',
      component: Layout404
    },
    {
      path: '*',
      beforeEnter: (to, from, next) => { next('/nenalezeno?pid=' + to.fullPath) }
    }
  ]
});

export default router;
