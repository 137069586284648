import {createColorByName, truncate, links, stripURLintoDomain, csuColor, joinWithLast, betterURL, date, beautifyDate, beautifyNumber, colorOfParty, gradient, getLinkType, isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'layout-candidate',
	props: ['hash'],
	data: function () {
		return {}
	},
	computed: {
		loaded: function () {
			var test = true;

			return test;
		},
		color: function () {
			return csuColor(this.core);
		},
		links: function () {
			return links(this.core.custom.links);
		},
		core: function () {
			var loaded = this.$store.getters.pdv('candidate/list');

			if (loaded) {
				loaded = loaded.list.find(x => x.csu.main.hash === this.hash);

				this.$store.dispatch('ga', {title: loaded.csu.main.name + ' ' + loaded.csu.main.family + ', kandidát' + (loaded.csu.main.sex === 2 ? 'ka' : '') + ' na prezidenta České republiky'});

				setTimeout(() => {
					if (location.hash.length > 1) this.$refs.anchors.trigger(location.hash);
				}, 1500);
			}

			return loaded;
		},
		history: function () {
			return this.$store.getters.getSource('volby/p/2023/history');
		},
		data: function () {
			var loaded = this.$store.getters.pdv('candidate/fetch/' + this.hash);

			return loaded ? loaded.data.custom : null;
		},
		sizeOfPriority: function () {
			return window.innerWidth < 1024 ? 24 : 48
		},
		bets: function () {
			var list = [];

			this.$store.getters.cis('bets').forEach(bet => {
				var obj = {
					id: bet.id,
					hash: bet.hash,
					url: bet.url,
					list: []
				}

				list.push(obj);
			});

			if (this.data && this.data.bets.length > 0) {
				this.data.bets.forEach(bet => {
					var b = list.find(x => x.id === bet.aid)

					if (b) b.list.push(bet);
				});
			}

			return list;
		},
		medialist: function () {
			var list = [];
			this.data.media.forEach(bet => {
				bet.og = JSON.parse(bet.og);
				list.push(bet);
			});

			return list;
		},
		donors: function () {
			var list = [];

			if (this.data) {
				this.data.budget.forEach(item => {
					if (item.notable) {
						item.notable.forEach(arr => {
							list.push(arr);
						});
					}
				});
			}

			list.sort((a, b) => b[0] - a[0]);

			return list;
		},
		expenses: function () {
			var list = [];
			var offset = 0;

			if (this.data && this.data.budget[0].structure) {
				Object.keys(this.data.budget[0].structure).forEach(key => {
					var val = Math.floor((this.data.budget[0].structure[key] / Object.keys(this.data.budget[0].structure).reduce((a, b) => a + this.data.budget[0].structure[b], 0) * 10000) * 3.14 / 100);
					list.push({val, offset, color: createColorByName(this.$store.getters.cis('expenses')[0][key]), key});
					offset += val;
				});
			}

			return list;
		},
		events: function () {
			var list = [];

			if (this.data) {
				this.data.events.forEach(event => {
					if (new Date(event.date).getTime() >= new Date(new Date().toISOString().slice(0,10)).getTime()) {
						list.push(event);
					}
				})
			}

			list.sort((a, b) => a.date.localeCompare(b.date, 'cs'));

			return list.splice(0, 3);
		}
	},
	methods: {
		truncate,
		$: function (hash) {
			return this.$store.getters.party(hash)
		},
		createColorByName, joinWithLast, betterURL, beautifyNumber, date, gradient, getLinkType, beautifyDate, colorOfParty, isValidHttpUrl, csuColor, stripURLintoDomain,
		domain: function (url) {
			return stripURLintoDomain(url).split('/')[0]
		},
		mediaicon: function (url) {
			var outlets = ['blesk', 'denik', 'drbna', 'hn', 'idnes', 'irozhlas', 'lidovky', 'novinky', 'seznamzpravy'];
			var link = this.$store.state.root + 'static/icon/link.svg';

			outlets.forEach(o => {
				if (url.split(o).length > 1) link = this.$store.state.root + 'static/icon-media/' + o + '.jpg';
			})

			return link;
		}
	},
	mounted: function () {
		window.scrollTo(0, 1);
	},
	watch: {
		hash: function () {
			window.scrollTo(0, 1);
		},
		$route: function () {
			window.scrollTo(0, 1);
		}
	}
};
