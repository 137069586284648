import axios from 'axios';
import {betterURL} from '@/common/helpers';

export default {
	name: 'root-bets',
	props: ['hash', 'data'],
	data: function () {
		return {
			form: {},
			sending: false,
			sent: false
		}
	},
	computed: {
		valid: function () {
			return true;
		}
	},
	methods: {
		add: function () {
			if (this.valid) {
				this.sending = true;

				var obj = {
					token: this.$store.state.auth,
					hash: this.hash || betterURL(this.form.name)
				}

				Object.keys(this.form).forEach(key => {
					obj[key] = this.form[key];
				});

				axios.post(this.$store.state.api + 'root/bets-add', obj).then((response) => {
					this.sending = false;

					if (response.data.code === 200) {
						this.sent = true;
						setTimeout(() => this.another(), 500);
					}
				});
			}
		},
		another: function () {
			this.form.value = -1;
			this.form.aid = null;
			this.form.name = null;
			this.form.date = null;

			this.$nextTick();
		}
	},
	mounted: function () {
		this.another();
	}
};
