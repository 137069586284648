import {csuColor, stripURLintoDomain, truncate} from '@/common/helpers';

export default {
	name: 'layout-questions-detail',
	props: ['hash'],
	data: function () {
		return {
			agency: 1,
			limit: null
		}
	},
  components: {},
	computed: {
		question: function () {
			return this.$store.getters.cis('topic').find(x => x.id === Number(this.hash.split('-')[0]));
		},
		data: function () {
			return this.$store.getters.pdv('questions/fetch/' + this.question.id);
		}
	},
	methods: {
		csuColor, stripURLintoDomain, truncate,
		name: function (hash) {
			var c = this.$store.getters.candidate(hash);
			return c.csu.main.name + ' ' + c.csu.main.family
		}
	},
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {title: "Otázka č. " + Number(this.hash.split('-')[0]) });
  }
};
