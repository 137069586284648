import {date, betterURL, csuColor} from '@/common/helpers';

export default {
	name: 'layout-polls',
	data: function () {
		return {}
	},
	components: {},
	computed: {
		events: function () {
			return this.$store.getters.pdv('candidate/events-all');
		},
		done: function () {
			return this.$store.getters.pdv('candidate/events-done');
		}
	},
	methods: {
		date, betterURL, csuColor,
		eventsOf: function (hash) {
			var list = [];

			var dates = [
				{
					id: 2022,
					list: [
						{id: 11, list: [
							14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30
						]},
						{id: 12, list: [
							"01","02","03","04","05","06","07","08","09",10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31
						]}
					]
				},
				{
					id: 2023,
					list: [
						{id: "01", list: [
							"01","02","03","04","05","06","07","08","09",10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28
						]}
					]
				}
			];

			dates.forEach(y => {
				y.list.forEach(m => {
					m.list.forEach(d => {
						var o = {
							date: y.id + '-' + m.id + '-' + d,
							event: false,
							debate: false
						}

						if (this.events) {
							o.event = this.events.data.filter(x => x.date === o.date && x.hash === hash);
							o.debate = this.events.data.filter(x => x.date === o.date && x.hash === 'generic' && x.relates && x.relates.split(hash).length > 1);
						}

						if (o.date >= new Date().toISOString().split('T')[0]) list.push(o);
					});
				})
			})

			return list;
		}
	},
	mounted: function () {
		window.scrollTo(0, 1);
		this.$store.dispatch("ga", {title: "Přehled chystaných akcí"});
	}
};
