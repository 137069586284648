import {date} from '@/common/helpers';

export default {
	name: 'layout-news',
	data: function () {
		return {}
	},
  components: {},
	computed: {
		news: function () {
			return this.$store.getters.pdv('tip/news/9999');
		},
		news_sorted: function () {
			if (!this.news) return undefined;

			var list = [
				{id: '2023', months: [
					{id: '01', name: "Leden", list: []}
				]},
				{id: '2022', months: [
					{id: '12', name: "Prosinec", list: []},
					{id: '11', name: "Listopad", list: []},
					{id: '10', name: "Říjen", list: []},
					{id: '09', name: "Září", list: []},
					{id: '08', name: "Srpen", list: []},
					{id: '07', name: "Červenec", list: []},
					{id: '06', name: "Červen", list: []},
					{id: '05', name: "Květen", list: []},
					{id: '04', name: "Duben", list: []},
					{id: '03', name: "Březen", list: []},
					{id: '02', name: "Únor", list: []},
					{id: '01', name: "Leden", list: []}
				]},
				{id: '2021', months: [
					{name: "Starší", list: []}
				]}
			];

			this.news.list.forEach(item => {

				// if (item.type === "president") {
					var date = item.updated.split(' ')[0].split('-');

					if (date[0] === '2023' || date[0] === '2022') {
						list.find(x => x.id === date[0]).months.find(x => x.id === date[1]).list.push(item);
					} else {
						list[2].months[0].list.push(item);
					}
				// }
			})

			return list;
		},
		newest: function () {
			if (!this.news) return undefined;

			var list = [];
			var days = [];
			var cando = true;

			this.news.list.forEach(item => {
				if (cando) {
					if (!days.find(x => x === item.updated.split(' '))) {
						days.push(item.updated.split(' '));
					}

					if ((days.length < 4 || list.length < 5)) {
						list.push(item);
					} else {
						cando = false;
					}
				}
			});

			return list;
		}
	},
  methods: {
		date
	},
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {title: "Archiv novinek"});
  }
};
