import {csuColor, betterURL, date, colorOfParty, beautifyNumber} from '@/common/helpers';

export default {
	name: 'layout-kandidati',
	data: function () {
		return {
			querySearchTown: null,
			querySearchTownSearching: false
		}
	},
  components: {},
	computed: {
		loaded: function () {
			var test = true;

			// if (!this.$store.state.dynamic.source.find(x => x.source === 'obecne/okresy-flat')) test = false;
			// if (!this.$store.state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat')) test = false;
			// if (!this.$store.state.dynamic.pdv.find(x => x.source === 'party/list')) test = false;

			return test;
		},
		list: function () {
			return this.$store.getters.pdv('candidate/list');
		},
		tips: function () {
			return this.$store.getters.pdv('tip/fetch/president', 2022);
		},
		news: function () {
			return this.$store.getters.pdv('tip/news/5');
		}
	},
  methods: {
		$: function (hash) {
			return this.$store.getters.party(hash)
		},
		betterURL,
		date,
		colorOfParty,
		beautifyNumber,
		csuColor,
		getLinkFromHash: function (item) {
			var link = "";
			var hash = Number(String(item.hash).split('-')[0]);
			var obj;

			if (hash < 100) {
				obj = this.$store.state.static.senate.list.find(x => x.id === hash);
				link = "/obvod/" + hash + '-' + betterURL(obj.display);
			} else {
				obj = this.$store.getters.town(hash);
				link = "/obec/" + hash + '-' + betterURL(obj.data[6]);
			}

			return link;
		},
		pct: function (count, base) {
			return Math.round(10000 * count / base)/100
		}
  },
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {title: "Prezidentské volby 2023"});
  }
};
