import axios from 'axios';
import {date as bdate, stripURLintoDomain} from '@/common/helpers';

export default {
	name: 'admin-news',
	props: ['data'],
	data: function () {
		return {
			date: new Date().toISOString().split('T')[0],
			title: null,
			source: null,
			relates: [],
			list: [],
			id: null
		}
	},
	computed: {
		src: function () {
			if (this.url) {
				return this.url;
			} else {
				return '/static/missing.png';
			}
		},
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		bdate,
		update_photo: function (photo, member) {
			var self = member || this;

			self.image = this.$store.state.server + photo.link;
		},
		click_edit: function (item, state) {
			item.edit = state;
			item.tick++;
			this.tick++;
		},
		click_add: function () {
			axios.post(this.$store.state.api + 'admin/news-add-2', {
				hash: this.relates.length === 1 ? this.relates[0] : 'generic',
				outlet: stripURLintoDomain(this.source),
				date: this.date + ' 00:00:00',
				title: this.title,
				priority: 1,
				relates: this.relates.length > 0 ? this.relates.join(',') : undefined,
				url: this.source,
				token: this.$store.state.auth
			}).then((response) => {
				if (response.data.code === 200) {
					this.tick++;

					this.date = null;
					this.title = null;
					this.relates = [];
					this.source = null;
				}
			});
		},
		click_relates: function (hash) {
			var index = this.relates.indexOf(hash);

			if (index > -1) {
				this.relates.splice(index, 1)
			} else {
				this.relates.push(hash);
			}
		}
	}
};
