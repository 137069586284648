import BetsChart from '@/components/charts/components/bets/do';
import {links, stripURLintoDomain, csuColor, joinWithLast, betterURL, date, beautifyDate, beautifyNumber, colorOfParty, gradient, getLinkType, isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'layout-candidate',
	props: ['hash'],
	data: function () {
		return {
			sundays: this.$store.state.sundays
		}
	},
	components: {
		BetsChart
	},
	computed: {
		loaded: function () {
			var test = true;

			return test;
		},
		color: function () {
			return csuColor(this.core);
		},
		links: function () {
			return links(this.core.custom.links);
		},
		core: function () {
			var loaded = this.$store.getters.pdv('candidate/list');

			if (loaded) {
				loaded = loaded.list.find(x => x.csu.main.hash === this.hash);

				this.$store.dispatch('ga', {title: loaded.csu.main.name + ' ' + loaded.csu.main.family + ', volební průzkumy a sázkařské kurzy na kandidát' + (loaded.csu.main.sex === 2 ? 'ku' : 'a') + ' na prezidenta České republiky'});

				setTimeout(() => {
					if (location.hash.length > 1) this.$refs.anchors.trigger(location.hash);
				}, 1500);
			}

			return loaded;
		},
		data: function () {
			var loaded = this.$store.getters.pdv('candidate/fetch/' + this.hash);

			return loaded ? loaded.data.custom : null;
		},
		polls: function () {
			return this.$store.getters.pdv('polls/all');
		},
		social: function () {
			var list = [];

			if (this.data) {
				this.data.social.forEach(entry => {
					if (!list.find(x => x.link === entry.link)) {
						list.push(entry);
					}
				});
			}

			return list;
		},
		bets_data: function () {
			// [{points: [6,10.5,20,55,55,55,55,75], color: '#f00'}, {points: [6,10.5,20,null,55,55,55,55], color: '#0f0'}, {points: [6.5,11,18,40,57,50,50,50], color: '#00f'}, {points: [6,10,15,15,50,50,50,50], color: '#f0f'}]

			var list = [];

			this.$store.getters.cis('bets').forEach(betagency => {
				var obj = {
					points: [],
					color: betagency.bg,
					filter: betagency.hash,
					hash: betagency.hash
				}

				if (this.data) {
					if (this.data.bets && this.data.bets) {
						this.$store.state.sundays.slice().reverse().forEach(sun => {
							var value = this.data.bets.find(x => x.aid === betagency.id && x.date === sun);

							obj.points.push(value ? value.value : null);
						});
					}
				}

				list.push(obj);
			})

			return list;
		},
		bets_legend: function () {
			var list = [];

			this.$store.getters.cis('bets').forEach(item => {
				var o = {
					hash: item.hash,
					name: item.hash.toUpperCase(),
					color: item.bg,
					link: '/sazkove-kurzy'
				}

				list.push(o);
			})

			return list;
		}
	},
  methods: {
		$: function (hash) {
			return this.$store.getters.party(hash)
		},
		joinWithLast, betterURL, beautifyNumber, date, gradient, getLinkType, beautifyDate, colorOfParty, isValidHttpUrl, csuColor,
		domain: function (url) {
			return stripURLintoDomain(url).split('/')[0]
		},
		mediaicon: function (url) {
			var outlets = ['blesk', 'denik', 'drbna', 'hn', 'idnes', 'irozhlas', 'lidovky', 'novinky', 'seznamzpravy'];
			var link = this.$store.state.root + 'static/icon/link.svg';

			outlets.forEach(o => {
				if (url.split(o).length > 1) link = this.$store.state.root + 'static/icon-media/' + o + '.jpg';
			})

			return link;
		}
  },
  mounted: function () {
    window.scrollTo(0, 1);
  },
	watch: {
		hash: function () {
			window.scrollTo(0, 1);
		},
		$route: function () {
			window.scrollTo(0, 1);
		}
	}
};
