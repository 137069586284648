import PBS1 from '@/components/pbs-round-1/do';
import PBS2 from '@/components/pbs-round-2/do';
import {date} from '@/common/helpers';

export default {
	name: 'layout-polls',
	data: function () {
		return {}
	},
  components: {
		'pbs-round-1': PBS1,
		'pbs-round-2': PBS2
	},
	computed: {
		data: function () {
			return this.$store.getters.pdv('polls/all');
		}
	},
  methods: {
		date
	},
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {title: "Volební průzkumy a odhady"});
  }
};
