import axios from 'axios';
import {betterURL} from '@/common/helpers';

export default {
	name: 'root-bets',
	props: ['hash', 'data', 'list'],
	data: function () {
		return {
			form: {
				aid: null
			},
			ls: [],
			sending: false,
			sent: false
		}
	},
	computed: {
		valid: function () {
			return true;
		}
	},
	methods: {
		add: function () {
			if (this.valid) {
				this.sending = true;

				var obj = {
					token: this.$store.state.auth,
					list: []
				}

				this.ls.forEach(item => {
					if (!!item.blank && !!item.name) {
						if (item.value) {
							item.hash = betterURL(item.name);
							obj.list.push(item);
						}

					} else {
						if (item.value) {
							obj.list.push({hash: item.hash, value: item.value});
						}
					}


				});

				Object.keys(this.form).forEach(key => {
					obj[key] = this.form[key];
				});

				axios.post(this.$store.state.api + 'root/bets-add-multiple', obj).then((response) => {
					this.sending = false;

					if (response.data.code === 200) {
						this.sent = true;
						setTimeout(() => this.another(), 500);
					}
				});
			}
		},
		another: function () {
			this.form.value = -1;
			this.form.aid = null;
			this.form.name = null;
			this.form.date = new Date().toISOString().split("T")[0];

			this.$nextTick();
		}
	},
	mounted: function () {
		this.another();

		this.list.forEach(item => {
			if (item.csu.main.level === 5) {
				this.ls.push({
					name: item.csu.main.name + ' ' + item.csu.main.family,
					hash: item.csu.main.hash,
					value: null
				})
			}
		});

		// for (var i = 0; i < 10; i++) {
		// 	this.ls.push({
		// 		name: null,
		// 		hash: null,
		// 		value: null,
		// 		blank: true
		// 	})
		// }
	}
};
