import axios from 'axios';

export default {
	name: 'admin-embeds',
	props: ['hash', 'phash', 'data', 'open'],
	data: function () {
		return {
			code: null,
			label: null,
			priority: false,
			tick: 0,
			list: [],
			id: null
		}
	},
	computed: {
		src: function () {
			if (this.url) {
				return this.url;
			} else {
				return '/static/missing.png';
			}
		},
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		click_add: function () {
			axios.post(this.$store.state.api + 'admin/embeds-add', {
				hash: this.hash,
				code: this.code,
				label: this.label,
				priority: this.priority ? 1 : 2,
				token: this.$store.state.auth
			}).then((response) => {
				if (response.data.code === 200) {
					this.tick++;

					var obj = {
						code: this.code,
						label: this.label,
						priority: this.priority ? 1 : 2,
						id: response.data.id,
						removed: false,
						edit: false,
						tick: 0
					};

					this.code = null;
					this.label = null;
					this.priority = false;

					this.list.push(obj);
				}
			});
		},
		click_update: function (item) {
			axios.post(this.$store.state.api + 'admin/embeds-update', {
				id: item.id,
				hash: this.hash,
				priority: 1,
				token: this.$store.state.auth
			}).then(() => {
				item.removed = true;
				item.tick++;
				this.tick++;
			});
		},
		click_remove: function (item) {
			axios.post(this.$store.state.api + 'admin/embeds-remove', {
				id: item.id,
				hash: this.hash,
				token: this.$store.state.auth
			}).then(() => {
				item.removed = true;
				item.tick++;
				this.tick++;
			});
		}
	},
	mounted: function () {
		this.data.forEach(item => {
			var obj = {
				code: item.code,
				label: item.label,
				priority: item.priority,
				removed: false,
				edit: false,
				tick: 0
			};

			this.list.push(obj);
		});
	}
};
