import Vue from 'vue';

import Bets from './bets/do';
import BetsMultiple from './bets-multiple/do';
import Budget from './budget/do';
import Polls from './polls/do';
import PollsList from './polls-new/do';
import Social from './social/do';
import News from './news/do';

Vue.component('r-bets', Bets);
Vue.component('r-bets-multiple', BetsMultiple);
Vue.component('r-budget', Budget);
Vue.component('r-polls', Polls);
Vue.component('r-polls-list', PollsList);
Vue.component('r-social', Social);
Vue.component('r-news', News);
