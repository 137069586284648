import {date, csuColor} from '@/common/helpers';

export default {
	name: 'layout-media',
	data: function () {
		return {
			agency: 1,
			limit: null,
			limitMedia: true,
			limitPolls: true
		}
	},
  components: {},
	computed: {
		media: function () {
			return this.$store.getters.pdv('candidate/media-all');
		},
		media_filter: function () {
			var list = [];

			this.$store.getters.cis('media').forEach(med => {
				if (this.$store.getters.cis('questions').filter(x => x.media === med.hash).length > 0) {
					list.push(med);
				}
			});

			list.sort((a, b) => a.hash.localeCompare(b.hash, 'cs'));

			return list;
		}
	},
  methods: {
		date, csuColor,
		sorted: function (agency, day) {
			var list = [];

			this.$store.getters.candidates().forEach(cand => {
				var o = {
					cand,
					hash: cand.csu.main.hash,
					entry: this.data.list.find(x => x.date === day).list.find(x => x.aid === agency && x.hash === cand.csu.main.hash)
				}

				list.push(o);
			});

			list.sort((a, b) => (a.entry ? a.entry.value : 9999) - (b.entry ? b.entry.value : 9999));

			return list;
		}
	},
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {title: "Volby v médiích"});

		setTimeout(() => {
			if (location.hash.length > 1) this.$refs.anchors.trigger(location.hash);
		}, 1500);
  }
};
