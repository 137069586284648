import axios from 'axios';
import {isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'tip-form',
	props: ['id', 'hint', 'type', 'hash'],
	components: {},
	data: function () {
		return {
			url: '',
			note: null,
			author: null,
			sent: false,
			sending: false,
			showHints: false
		}
	},
	computed: {
		valid: function () {
			return isValidHttpUrl(this.url);
		}
	},
	methods: {
		send: function () {
			if (this.valid) {
				this.sending = true;

				axios.post(this.$store.state.api + 'tip/add/' + this.type, {
					hash: this.hash,
					path: this.$route.path,
					type: this.type,
					source: encodeURIComponent(this.url),
					note: this.note ? encodeURIComponent((this.hint ? this.hint + ': ' : '') + this.note) : null,
					author: this.author ? encodeURIComponent(this.author) : null
				}).then(() => {
					this.sending = false;
					this.sent = true;

					setTimeout(() => this.another(), 1500);
				});
			}
		},
		another: function () {
			this.sent = false;
			this.url  = '';
			this.note = null;
		}
	}
};
