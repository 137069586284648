export default {
	name: 'HistoryItem',
	props: ['ev', 'history'],
	methods: {
		label_make: function (ev) {
			var s = '';
			var y = ev.date.split('').splice(-4,4).join('');

			if (ev.elections === 'snemovni-volby') s = 'Sněmovní volby, ' + y;
			if (ev.elections === 'evropske-volby') s = 'Evropské volby, ' + y;
			if (ev.elections === 'senatni-volby') s = 'Senátní volby, ' + y + ', ' + ev.round + '. kolo';
			if (ev.elections === 'prezidentske-volby') s = 'Prezidenské volby, ' + y + ', ' + ev.round + '. kolo';
			if (ev.elections === 'komunalni-volby') s = 'Komunální volby, ' + y;
			if (ev.elections === 'krajske-volby') s = 'Krajské volby, ' + y;

			return s;
		},
		status_make: function (ev) {
			if (['snemovni-volby', 'krajske-volby', 'komunalni-volby', 'evropske-volby'].indexOf(ev.elections) > -1) {
				return ev.elected === "1" ? "zvolen" : "nezvolen"
			} else if (ev.round === "1") {
				return ev.elected === "1" ? "do 2. kola" : "nezvolen"
			} else {
				return ev.elected === "1" ? "zvolen" : "nezvolen"
			}
		}
	}
};
