import {colorOfParty, csuColor, truncate} from '@/common/helpers';

export default {
	name: 'senate-grid-item',
	props: ['data', 'photo', 'links', 'motto'],
	data: function () {
		return {
			buttons: [
				{type: 'fb'},
				{type: 'tw'},
				{type: 'ig'}
			]
		}
	},
	computed: {
		color: function () {
			return csuColor(this.data);
		},
		link: function () {
			var parts = ['/kandidat'];
			parts.push(this.data.csu.main.hash);

			return parts.join('/');
		},
		program: function () {
			return this.data.custom.links ? this.data.custom.links.find(x => x.type === 'program') : null;
		},
		web: function () {
			return this.data.custom.links ? this.data.custom.links.find(x => x.type === 'web') : null;
		}
	},
	methods: {
		colorOfParty, truncate,
		support: function (data) {
			var list = [];
			var json = JSON.parse("[" + data + "]");

			json.forEach(member => {
				if (typeof member === 'number') {
					list.push(this.$store.getters.party(member))
				} else if (typeof member === 'object') {
					list.push({
						name: member.name || member.short,
						short: member.short,
						color: member.color || colorOfParty(member.short)
					})
				} else {
					list.push({
						name: member,
						short: member,
						color: colorOfParty(null, member)
					})
				}
			});

			return list;
		}
	}
};
