import {csuColor, date} from "@/common/helpers";
import ResultsGraphDuel from "@/components/results/graphs/graph-duel/do";

export default {
	name: 'pbs-round-2',
	props: ['week', 'duel'],
	components: {ResultsGraphDuel},
	data: function () {
		return {
			use_date: this.week || new Date().toISOString().split('T')[0],
			use_balancer: false,
			use_gradation: true,
			use_pbs: true,
			use_type: 'all'
		}
	},
	computed: {
		polls: function () {
			var data = this.$store.getters.pdv('polls/all');
			var list = [];
			var duel = this.duel || ['danuse-nerudova', 'petr-pavel'];

			if (data) {
				data.data.forEach(collection => {
					var poll = this.$store.getters.cis('polls').find(x => x.id === collection.id);

					if (poll && poll.type === 3 && poll.level === 1 && poll.status === 1) {
						for (var i = 1; i < 20; i++) {
							var sub = collection.entries.filter(x => x.sub === i);

							if (sub.length === 2) {
								if (sub.find(x => x.hash === duel[0]) && sub.find(x => x.hash === duel[1])) {
									list.push({
										poll,
										entries: sub
									})
								}
							}
						}
					}
				})
			}

			return list.length > 0 ? list : undefined;
		},
		bets: function () {
			var data = this.$store.getters.pdv('bets/all');
			var list = [];

			if (data) {
				this.$store.state.sundays.filter(x => x < this.use_date).forEach(sunday => {
					var o = {
						date: sunday,
						list: []
					}

					this.$store.getters.candidates().filter(x => x.csu.main.level === 5).forEach(candidate => {
						var cand = {
							hash: candidate.csu.main.hash,
							bets: data.list.find(x => x.date === sunday).list.filter(x => x.hash === candidate.csu.main.hash),
							avg: 0
						}

						cand.avg = Math.round(100 * cand.bets.reduce((a, b) => a + b.value, 0) / cand.bets.length) / 100;

						o.list.push(cand);
					});

					list.push(o);
				});
			}

			return data ? list : undefined;
		},
		sundays: function () {
			var entry = this.$store.state.sundays.filter(x => x < this.use_date);
			var list = [];

			var types = {
				linear: [10,9,8,7,6,5,4,3,2,1,0,0,0,0,0,0,0,0,0,0,0,0],
				graded: [10,7,5.2,4,3,2.2,1.5,1,0.6,0.2,0.05,0,0,0,0,0,0]
			}

			entry.forEach((e, i) => {
				var obj = {
					coef: types[this.use_gradation ? 'graded' : 'linear'][i],
					date: e,
					polls: {
						mid: this.use_pbs || this.use_type === 'all' || this.use_type === 'mid' ? this.polls.filter(x => x.poll.pp_mid === e) : [],
						pub: this.use_pbs || this.use_type === 'all' || this.use_type === 'pub' ? this.polls.filter(x => x.poll.pp_pub === e) : [],
						cor: this.use_pbs || this.use_type === 'all' ? this.polls.filter(x => x.poll.pp_cor === e) : []
					}
				};

				if (obj.coef > 0) list.push(obj)
			})

			return list;
		},
		vals: function () {
			var list = [];

			this.sundays.forEach(sunday => {
				var obj = {
					date: sunday.date,
					coef: sunday.coef,
					values: []
				}

				sunday.polls.mid.forEach(x => {
					var o = {
						coef: 8,
						type: 'mid',
						poll: x.poll,
						vals: x.entries
					}

					obj.values.push(o);
				})

				sunday.polls.pub.forEach(x => {
					var o = {
						coef: 2,
						type: 'pub',
						poll: x.poll,
						vals: x.entries
					}

					obj.values.push(o);
				})

				sunday.polls.cor.forEach(x => {
					var o = {
						coef: 2,
						type: 'cor',
						poll: x.poll,
						vals: x.entries
					}

					obj.values.push(o);
				})

				list.push(obj);
			});

			return list;
		},
		mood: function () {
			var list = [];

			this.vals.forEach(val => {
				var obj = {
					date: val.date,
					coef: val.coef,
					values: []
				}

				val.values.forEach(v => {
					var o = {
						coef: v.coef,
						type: v.type,
						poll: v.poll,
						vals: v.vals,
						mood: []
					}

					v.vals.forEach(item => {
						var m;

						if (this.use_pbs === false || o.type === 'mid') o.mood.push(item);
						if (this.use_pbs === true && o.type === 'pub') {
							m = {
								hash: item.hash,
								value: item.value
							}

							m.betAtDate = this.bets.find(x => x.date === obj.date).list.find(x => x.hash === item.hash);
							m.betAtPub = this.bets.find(x => x.date === o.poll.pp_mid) ? this.bets.find(x => x.date === o.poll.pp_mid).list.find(x => x.hash === item.hash) : undefined;

							if (m.betAtDate && m.betAtPub) {
								m.betAtDate = m.betAtDate.avg;
								m.betAtPub = m.betAtPub.avg || m.betAtDate;
								m.original = m.value;
								m.modifier = m.betAtDate / m.betAtPub - 1;

								// console.log(1, m);

								m.value -= m.modifier * m.value / 2;

								// console.log(2, m);
							}

							o.mood.push(m); // to-do
						}
						if (this.use_pbs === true && o.type === 'cor') {
							m = {
								hash: item.hash,
								value: item.value
							}

							m.betAtDate = this.bets.find(x => x.date === obj.date).list.find(x => x.hash === item.hash);
							m.betAtPub = this.bets.find(x => x.date === o.poll.pp_pub).list.find(x => x.hash === item.hash);

							if (m.betAtDate && m.betAtPub) {
								m.betAtDate = m.betAtDate.avg;
								m.betAtPub = m.betAtPub.avg;
								m.original = m.value;
								m.modifier = m.betAtDate / m.betAtPub - 1;
								m.value -= m.modifier * m.value / 2;
							}

							o.mood.push(m); // to-do
						}
					});

					obj.values.push(o);
				});

				list.push(obj);
			});

			return list;
		},
		pbs: function () {
			var list = [];

			this.$store.getters.candidates().filter(x => x.csu.main.level === 5).forEach(candidate => {
				var obj = {
					hash: candidate.csu.main.hash,
					sum: 0,
					coef: 0,
					result: 0,
					balanced: 0
				}

				this.mood.forEach(sunday => {
					sunday.values.forEach(val => {
						var c = val.mood.find(x => x.hash === obj.hash);

						// console.log(sunday, val, c);

						if (c) {
							obj.sum += (sunday.coef || 1) * (val.coef || 1) * c.value;
							obj.coef += (sunday.coef || 1) * (val.coef || 1);
						}
					})
				});

				obj.result = obj.coef > 0 ? Math.round(100 * obj.sum / obj.coef) / 100 : 0;
				obj.balanced = obj.result;

				list.push(obj);
			})

			// BALANCE

			if (this.use_balancer) {
				for (var i = 0; i < 10; i++) {
					var sum = 100 - list.reduce((a, b) => a + b.balanced, 0);

					list.forEach(obj => {
						if (sum != 0) {
							obj.balanced = Math.floor(100 * (obj.balanced + sum * obj.balanced / 100)) / 100;
						}
					})
				}

				list[0].balanced = Math.round(100 * (list[0].balanced + 100 - list.reduce((a, b) => a + b.balanced, 0))) / 100;
			}

			return list;
		},
		graph: function () {
			var obj = {
				about: {
					agency: 'Programy do voleb',
					comment: 'PBS skóre',
					date: this.use_date,
				},
				list: []
			}

			this.pbs.forEach(item => {
				var o = {
					hash: item.hash,
					pct: item.balanced,
					link: '/kandidat/' + item.hash,
					item
				}

				o.person = this.$store.getters.candidate(item.hash);

				o.short = o.person.csu.main.family;
				o.initials = o.person.csu.main.name.charAt(0) + o.person.csu.main.family.charAt(0);
				o.color = csuColor(o.person);
				o.photo = o.person.custom.photo.length > 0 ? o.person.custom.photo[0].url : null;

				obj.list.push(o);
			});

			obj.list.sort((a, b) => b.pct - a.pct);

			return obj;
		}
	},
	methods: {
		date
	}
};
