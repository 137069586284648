import axios from 'axios';

export default {
	name: 'admin-team',
	props: ['hash', 'phash', 'data', 'type'],
	data: function () {
		return {
			photo: null,
			url: null,
			name: null,
			comment: null,
			role: null,
			tick: 0,
			list: [],
			id: null
		}
	},
	computed: {
		src: function () {
			if (this.url) {
				return this.url;
			} else {
				return '/static/missing.png';
			}
		},
		token: function () {
			return this.$route.path.split('/').pop();
		},
		headline: function () {
			var s = "Tým";

			if (this.type === 'advisors') s = 'Tým poradců';
			if (this.type === 'team') s = 'Volební tým';
			if (this.type === 'office') s = 'Hradní tým';

			return s;
		}
	},
	methods: {
		update_photo: function (photo, member) {
			var self = member || this;

			self.photo = this.$store.state.server + photo.link;
		},
		click_edit: function (photo, state) {
			photo.edit = state;
			photo.tick++;
			this.tick++;
		},
		click_add: function () {
			axios.post(this.$store.state.api + 'admin/team-add', {
				type: this.type,
				hash: this.hash,
				name: this.name,
				role: this.role,
				comment: this.comment,
				url: this.url,
				photo: this.photo,
				token: this.$store.state.auth
			}).then((response) => {
				if (response.data.code === 200) {
					this.tick++;

					var obj = {
						name: this.name,
						role: this.role,
						photo: this.photo,
						comment: this.comment,
						url: this.url,
						id: response.data.id,
						removed: false,
						edit: false,
						tick: 0
					};

					this.list.push(obj);

					this.name = null;
					this.role = null;
					this.comment = null;
					this.photo = null;
				}
			});
		},
		click_update: function (member) {
			axios.post(this.$store.state.api + 'admin/team-update', {
				type: this.type,
				id: member.id,
				hash: this.hash,
				name: member.name,
				role: member.role,
				comment: member.comment,
				url: member.url,
				photo: member.photo,
				token: this.$store.state.auth
			}).then((response) => {
				member.id = response.data.id;
				this.click_edit(member, false);
			});
		},
		click_remove: function (member) {
			axios.post(this.$store.state.api + 'admin/team-remove', {
				type: this.type,
				id: member.id,
				hash: this.hash,
				token: this.$store.state.auth
			}).then(() => {
				member.removed = true;
				member.tick++;
				this.tick++;
			});
		}
	},
	mounted: function () {
		this.data.forEach(item => {
			var obj = {
				name: item.name,
				role: item.role,
				comment: item.comment,
				url: item.url,
				photo: item.photo,
				id: item.id,
				removed: false,
				edit: false,
				tick: 0
			};

			this.list.push(obj);
		});
	}
};
