export default {
	name: 'CompareSupport',
	props: ['list', 'color', 'type', 'person'],
	computed: {
		rev: function () {
			var list = [];

			this.list.filter(x => x.type < 3).forEach(x => list.push(x));
			list.reverse();

			return list;
		}
	}
};
