import {betterURL, date, csuColor} from '@/common/helpers';

export default {
	name: 'NewsItem',
	props: ['data', 'direct', 'noSource'],
	data: function () {
		return {}
	},
	computed: {
		loaded: function () {
			return this.$store.state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat');
		},
		type: function () {
			var icon = 'link';

			if (['fb', 'tw', 'yt', 'ig', 'linkedin', 'wiki'].indexOf(this.data.outlet) > -1) {
				icon = this.data.outlet;
			}

			if (['program', 'answers'].indexOf(this.data.type) > -1) {
				icon = this.data.type;
			}

			if (['blesk', 'denik', 'drbna', 'hn', 'idnes', 'irozhlas', 'lidovky', 'novinky', 'seznamzpravy'].indexOf(this.data.outlet) > -1) {
				icon = undefined;
			}

			return icon;
		},
		img: function () {
			var icon = undefined;

			if (['fb', 'tw', 'yt', 'ig', 'linkedin', 'wiki'].indexOf(this.data.outlet) > -1) {
				icon = undefined;
			}

			if (['blesk', 'denik', 'drbna', 'hn', 'idnes', 'irozhlas', 'lidovky', 'novinky', 'seznamzpravy'].indexOf(this.data.outlet) > -1) {
				icon = this.$store.state.static + 'media/' + this.data.outlet + '.jpg';
			}

			// if (['program'].indexOf(this.data.type) > -1) {
			// 	icon = '/static/icon/program-symbol.svg';
			// }

			return icon;
		},
			about: function () {
				var hash = this.data.hash;

				// if (this.direct) {
				// 	hash = this.direct;
				// }

				return this.$store.getters.candidate(hash)
			},
			_color: function () {
				return this.color || (this.about ? csuColor(this.about) : '#134D8A')
			}
	},
	methods: {
		betterURL, date, csuColor,
		getLinkFromHash: function (item) {

			var link = "";
			var hash = Number(String(item.hash).split('-')[0]);
			var obj;

			if (hash < 100) {
				obj = this.$store.state.static.senate.list.find(x => x.id === hash);
				link = "/obvod/" + hash + '-' + betterURL(obj.display);
			} else {
				obj = this.$store.getters.town(hash);
				if (obj && obj.data) {
					link = "/obec/" + hash + '-' + betterURL(obj.data[6]);
				} else {
					link = "/obec/" + hash;
				}

			}

			return link;
		}
	}
};
