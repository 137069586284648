import store from '@/store/store';

const getters = {
  vuexState: state => state,
  demo: (state) => (id) => {
    return state.structure.find(item => item.id === id)
  }
};

getters.getSource = (state) => (source) => {
  var lookup = state.dynamic.source.find(s => s.source === source);

  if (lookup) {
    return lookup.content;
  } else {
    new Promise((resolve, reject) => {
      store.dispatch('fetchSource', {
        source: source,
        onComplete: () => resolve(),
        onError: () => reject(new Error('load fail'))
      });
    }).then((resolver, rejected) => {
      if (rejected) {
        return undefined;
      } else {
        lookup = state.dynamic.source.find(s => s.source === source);
        return lookup.content;
      }
    });
  }
}

getters.getPromise = (state) => (source) => {
  return new Promise((resolve) => {
    var lookup = state.dynamic.source.find(s => s.source === source);

    if (lookup) {
      resolve(lookup.content);
    } else {
      new Promise((resolve, reject) => {
        store.dispatch('fetchSource', {
          source: source,
          onComplete: () => resolve(),
          onError: () => reject(new Error('load fail'))
        });
      }).then(() => {
        lookup = state.dynamic.source.find(s => s.source === source);
        resolve(lookup.content);
      });
    }
  })
}

getters.parties = (state) => () => {
  return state.dynamic.pdv.find(x => x.request === 'party/list').content.list;
}

getters.candidates = (state) => () => {
  return state.dynamic.pdv.find(x => x.request === 'candidate/list').content.list;
}

getters.candidate = () => (hash) => {
  var list = store.getters.candidates();
  return list.find(x => x.csu.main.hash === hash);
}

getters.media = (state) => (hash, placeholder) => {
  var logo = state.static + 'icon/' + (placeholder || 'link') + '.svg';
  var data = store.getters.cis('media', hash);

  // console.log(hash, data);

  if (data) {
    logo = data.logo;
  }

  return logo;
}

getters.cis = (state) => (name, hash) => {

  var enums = state.dynamic.pdv.find(x => x.request === 'system/enums');
  var obj = undefined;

  if (enums) {
    obj = name ? enums.content.data[name] : enums.content.data;

    if (enums.content.data[name] && hash) {
      obj = enums.content.data[name].find(x => x.hash === hash);
    }
  }

  return obj;
}

getters.party = (state) => (id) => {

  var parties = state.dynamic.pdv.find(x => x.request === 'party/list');
  var lup = null;

  if (parties) {
    if (typeof id === 'string') {
      lup = parties.content.list.find(x => x.hash === id);
    }
    if (typeof id === 'number') {
      lup = parties.content.list.find(x => x.reg === id);
    }
  }

  return lup;
}

getters.pdv = (state) => (request, api) => {
  var lookup = state.dynamic.pdv.find(s => s.request === request);

  // if (request.split('admin/').length > 1) {
  //   lookup = null;
  // }

  if (lookup) {
    return lookup.content;
  } else {
    new Promise((resolve, reject) => {
      store.dispatch('pdv', {
        request: request,
        api: api,
        onComplete: () => resolve(),
        onError: () => reject(new Error('load fail'))
      });
    }).then((resolver, rejected) => {
      if (rejected) {
        return undefined;
      } else {
        lookup = state.dynamic.pdv.find(s => s.request === request);
        return lookup.content;
      }
    });
  }
}

export default getters;
