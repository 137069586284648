const state = {
  dynamic: {
    partyList: [],
    source: [],
    pdv: []
  },
  voting: {
    token: null,
    home: {
      town: null,
      obvod: null
    },
    votes: []
  },
  personal: {
    preferredView: 'tiny',
    preferredViewLimit: 5
  },
    parties: {
      primary: [768, 7, 1, 47, 53, 720, 1114, 166, 721],
      secondary: [1245, 1187, 703, 724, 714, 1227, 5]
    },
  types: [
    {hash: 'priority', title: 'Volební priority přehledně', link: 'Volební priority', fetch: 'compare/generic/priority:updated', page: '/program-a-priority'},
    {hash: 'hodnoty', title: 'Hodnoty přehledně', link: 'Hodnoty a důvody', fetch: 'compare/generic/vals:updated', page: '/profil'},
    {hash: 'volebni-spoty', title: 'Volební spoty kandidátů', link: 'Volební spoty', fetch: 'compare/generic/embeds:updated', page: ''},
    {hash: 'medailonky', title: 'Medailonky kandidátů přehledně', link: 'Medailonky', fetch: 'compare/generic/about:updated', page: '/profil'},
    {hash: 'motto', title: 'Volební slogany kandidátů přehledně', link: 'Hesla a slogany', fetch: 'compare/generic/motto:updated', page: '/profil'},
    {hash: 'podporu', title: 'Podpora kandidátů přehledně', link: 'Vyjádření podpory', fetch: 'compare/generic/support:updated', page: '/profil'},
    {hash: 'dary-a-vydaje', title: 'Dary a výdaje přehledně', link: 'Dary a výdaje', fetch: 'compare/generic/budget:date', page: ''},
    {hash: 'volebni-tym', title: 'Volební týmy kandidátů přehledně', link: 'Volební tým', fetch: 'compare/generic/team:updated', page: '/poradci-a-tym'},
    {hash: 'tym-na-hrad', title: 'Koho si vezmou na Hrad?', link: 'Hradní tým', fetch: 'compare/generic/office:updated', page: '/poradci-a-tym'},
    {hash: 'poradce', title: 'Přehled poradců kandidátů', link: 'Poradci', fetch: 'compare/generic/advisors:updated', page: '/poradci-a-tym'},
    {hash: 'odkazy', title: 'Odkazy na weby a sociální sítě kandidátů', link: 'Odkazy', fetch: 'compare/generic/links:updated', page: ''},
    {hash: 'kontakty', title: 'Kontakty na kandidáty a mediální/pr týmy', link: 'Kontakty', fetch: 'compare/generic/contacts:updated', page: ''},
    {hash: 'sledovanost', title: 'Popularita a sledovanost kandidátů na sociálních sítích', link: 'Sledovanost', fetch: 'compare/generic/social:updated', page: '/pruzkumy-sazky-odhady-popularita'}
  ],
  sundays: ['2023-01-22', '2023-01-15', '2023-01-08', '2023-01-01', '2022-12-25', '2022-12-18', '2022-12-11', '2022-12-04', '2022-11-27', '2022-11-20', '2022-11-13', '2022-11-06', '2022-10-30', '2022-10-23', '2022-10-16', '2022-10-09'],
  dark: false,
  auth: null,
  server: 'https://data.programydovoleb.cz/',
  static: 'https://static.programydovoleb.cz/',
  root: 'https://prezident2023.programydovoleb.cz/',
  api: 'https://prezident2023.programydovoleb.cz/lib/app/api.php?action=',
  tick: 0,
  start: new Date().getTime(),
  cache: Math.floor(new Date().getTime() / 1000 / 60 / 60)
};

export default state;
