import axios from 'axios';

export default {
	name: 'admin-photo',
	props: ['hash', 'phash', 'data', 'open'],
	data: function () {
		return {
			url: null,
			licence: null,
			comment: null,
			original: null,
			some: 0,
			print: 0,
			tick: 0,
			id: null
		}
	},
	computed: {
		src: function () {
			if (this.url) {
				return this.url;
			} else {
				return '/static/missing.png';
			}
		},
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		updatePhoto: function (file) {
			axios.post(this.$store.state.api + 'admin/photo-update', {
				hash: this.hash,
				licence: this.licence,
				original: this.original,
				comment: this.comment,
				some: this.some ? 1 : 0,
				print: this.print ? 1 : 0,
				priority: 1,
				url: this.$store.state.server + file.link,
				token: this.$store.state.auth
			}).then((response) => {
				if (response.data.code === 200) {
					this.id = response.data.id;
					this.url = this.$store.state.server + file.link;
					this.tick++;
				}
			});
		},
		remove: function () {
			axios.post(this.$store.state.api + 'admin/photo-remove', {
				id: this.id,
				hash: this.hash,
				token: this.$store.state.auth
			}).then(() => {
				this.url = null;
				this.tick++;
			});
		}
	},
	mounted: function () {
		if (this.data && this.data[0]) {
			this.url = this.data[0].url;
			this.id = this.data[0].id;
		}
	}
};
