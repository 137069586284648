import axios from 'axios';
import {clearInput} from '@/common/helpers';

export default {
	name: 'faq-block',
	props: ['data', 'who', 'refs', 'color', 'hash'],
	data: function () {
		return {
			question: null,
			author: null,
			email: null,
			sent: false,
			sending: false
		}
	},
	methods: {
		send: function (status) {
			if (this.sending === true) return;
			this.sending = true;
			this.status = status;

			axios.post(this.$store.state.api + 'faq/ask', {
				content: clearInput(this.question),
				author: clearInput(this.author),
				email: clearInput(this.email),
				hash: this.hash,
			}).then((response) => {
				if (response.data.code === 200) {
					this.sending = false;
					this.sent = true;
					this.question = null;

					setTimeout(() => this.sent = false, 2000);
				}
			})
		}
	}
};
