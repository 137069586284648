import axios from 'axios';

export default {
	name: 'admin-sitemap',
	props: ['data'],
	data: function () {
		return {
			date: new Date().toISOString().split('T')[0]
		}
	},
	computed: {
		sitemap: function () {
			var list = [
				'<?xml version="1.0" encoding="UTF-8"?>',
				'<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">',
				this.create('')
			];

			this.data.list.filter(x => x.csu.main.level === 5).forEach(person => {
				list.push(this.create('kandidat/' + person.csu.main.hash));
				list.push(this.create('kandidat/' + person.csu.main.hash + '/profil'));
				list.push(this.create('kandidat/' + person.csu.main.hash + '/volebni-materialy'));
				list.push(this.create('kandidat/' + person.csu.main.hash + '/udalosti-a-setkani'));
				list.push(this.create('kandidat/' + person.csu.main.hash + '/fotogalerie'));
				list.push(this.create('kandidat/' + person.csu.main.hash + '/clanky-a-rozhovory'));
				list.push(this.create('kandidat/' + person.csu.main.hash + '/pruzkumy-sazky-odhady-popularita'));
				list.push(this.create('kandidat/' + person.csu.main.hash + '/program-a-priority'));
				list.push(this.create('kandidat/' + person.csu.main.hash + '/poradci-a-tym'));
				list.push(this.create('kandidat/' + person.csu.main.hash + '/otazky-a-temata'));
			});

			list.push(this.create('kandidati'));
			list.push(this.create('archiv-novinek'));
			list.push(this.create('o-projektu'));
			list.push(this.create('jak-volit'));
			list.push(this.create('otazky-a-temata'));
			list.push(this.create('volebni-pruzkumy'));
			list.push(this.create('sazkove-kurzy'));
			list.push(this.create('akce-udalosti-setkani'));
			list.push(this.create('tydenicek'));
			list.push(this.create('admin'));
			list.push('</urlset>');

			return list.join('\n');
		},
		code: function () {
			return this.sitemap.split('<').join('&lt;').split('>').join('&gt;')
		}
	},
	methods: {
		create: function (url) {
			return '<url><loc>https://prezident2023.programydovoleb.cz/' + url + '</loc><lastmod>' + this.date + '</lastmod><changefreq>daily</changefreq></url>'
		},
		add: function () {
				this.sending = true;

				axios.post(this.$store.state.api + 'admin/sitemap-add', {
					sitemap: this.sitemap,
					token: this.$store.state.auth
				}).then(() => {
					this.sending = false;
					this.sent = true;

					setTimeout(() => this.another(), 500);
				});
		},
		another: function () {
			this.sent = false;
		}
	}
};
