import axios from 'axios';
import {isValidHttpUrl, date, truncate} from '@/common/helpers';

export default {
	name: 'admin-media',
	props: ['hash', 'phash', 'data', 'open', 'root'],
	data: function () {
		return {
			list: [],
			url: '',
			opengraph: null,
			meta: null,
			title: null,
			perex: null,
			cover: null,
			placeholder: null,
			relates: [],
			updated: null,
			sent: false,
			sending: false,
			custom: false
		}
	},
	computed: {
		valid: function () {
			return isValidHttpUrl(this.url);
		},
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		isValidHttpUrl, date, truncate,
		update: function (file) {
			this.cover = 'https://data.programydovoleb.cz/' + file.link;
		},
		fetch: function () {
			if (this.valid) {
				this.sending = true;

				axios.post(this.$store.state.api + 'admin/media-fetch', {
					hash: this.hash,
					url: encodeURIComponent(this.url)
				}).then((response) => {
					this.sending = false;

					this.opengraph = response.data.og.ogs;
					this.meta = response.data.og.nogs;

					if (this.meta && this.meta.title) this.title = this.meta.title;
					if (this.meta && this.meta.description) this.description = this.meta.description;

					if (this.opengraph && this.opengraph.title) this.title = this.opengraph.title;
					if (this.opengraph && this.opengraph.description) this.perex = this.opengraph.description;
					if (this.opengraph && this.opengraph.image) {
						this.cover = this.opengraph.image;
						this.placeholder = this.opengraph.image;
					}

					this.custom = true;
				});
			}
		},
		add: function () {
			if (this.valid) {
				this.sending = true;

				axios.post(this.$store.state.api + 'admin/media-add', {
					hash: this.hash,
					url: encodeURIComponent(this.url),
					title: this.title ? this.title.replace(/["]/g, "“").replace(/[']/g, "‘") : undefined,
					perex: this.perex ? this.perex.replace(/["]/g, "“").replace(/[']/g, "‘") : undefined,
					cover: this.cover,
					relates: this.root ? this.relates.join(',') : undefined,
					opengraph: JSON.stringify(this.opengraph),
					meta: JSON.stringify(this.meta),
					token: this.$store.state.auth
				}).then((response) => {
					this.sending = false;
					this.sent = true;

					this.list.push({
						url: this.url,
						hash: this.hash,
						cover: this.cover,
						relates: this.relates,
						title: this.title,
						perex: this.perex,
						updated: new Date().toISOString().split('T').join(' '),
						id: response.data.id,
						removed: false
					});

					setTimeout(() => this.another(), 500);
				});
			}
		},
		remove: function (src) {
			axios.post(this.$store.state.api + 'admin/media-remove', {
				hash: this.hash,
				id: src.id,
				token: this.$store.state.auth
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.find(x => x.id === src.id).removed = true;
				}
			})
		},
		another: function () {
			this.sent = false;
			this.url  = '';
			this.cover = null;
			this.title = null;
			this.relates = [];
			this.perex = null;
		},
		click_relates: function (hash) {
			var index = this.relates.indexOf(hash);

			if (index > -1) {
				this.relates.splice(index, 1)
			} else {
				this.relates.push(hash);
			}
		}
	},
	mounted: function () {
		if (this.data) {
			this.data.filter(x => x.hash === (this.root ? 'generic' : this.hash)).forEach(src => {
				this.list.push({
					url: src.url,
					hash: src.hash,
					cover: src.cover,
					title: src.title,
					perex: src.perex,
					relates: src.relates,
					updated: src.updated,
					id: src.id,
					removed: false
				});
			})
		}
	}
};
