import {betterURL, truncate, beautifyNumber, createColorByName, date} from '@/common/helpers';

export default {
	name: 'CompareBudget',
	props: ['list', 'color', 'type', 'person'],
	methods: {
		betterURL, truncate, beautifyNumber, createColorByName, date
	},
	computed: {
		parsed: function () {
			var list = [];

			this.list.forEach(x => {

				var o = {
					account: x.account,
					date: x.date,
					funds: x.funds,
					income: x.income,
					spending: x.spending,
					source: x.source,
					structure: x.structure && typeof x.structure === "string" ? JSON.parse(x.structure) : x.structure
				}

				try {
					o.notable = x.notable && typeof x.notable === "string" ? JSON.parse(x.notable) : x.notable;
				} catch (e) {
					// do nothing
				}

				list.push(o);
			});

			return list;
		},
		donors: function () {
			var list = [];

			if (this.parsed) {
				this.parsed.forEach(item => {
					if (item.notable) {
						item.notable.forEach(arr => {
							list.push(arr);
						});
					}
				});
			}

			list.sort((a, b) => b[0] - a[0]);

			return list;
		},
		expenses: function () {
			var list = [];
			var offset = 0;

			if (this.parsed && this.parsed[0].structure) {
				Object.keys(this.parsed[0].structure).forEach(key => {
					var val = Math.floor((this.parsed[0].structure[key] / Object.keys(this.parsed[0].structure).reduce((a, b) => a + this.parsed[0].structure[b], 0) * 10000) * 3.14 / 100);
					list.push({val, offset, color: createColorByName(this.$store.getters.cis('expenses')[0][key]), key});
					offset += val;
				});
			}

			return list;
		},
	}
};
