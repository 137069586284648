import axios from 'axios';
import {truncate} from '@/common/helpers';

export default {
	name: 'root-social',
	props: ['hash', 'data'],
	data: function () {
		return {
			list: [],
			sending: false,
			sent: false
		}
	},
	computed: {
		valid: function () {
			return true;
		}
	},
	methods: {
		truncate,
		add: function (link) {
			if (this.valid) {
				this.sending = true;

				var obj = {
					token: this.$store.state.auth,
					hash: this.hash,
					type: link.item.type,
					link: link.item.url,
					value: link.value
				}

				axios.post(this.$store.state.api + 'root/social-add', obj).then((response) => {
					this.sending = false;

					if (response.data.code === 200) {
						link.sent = true;
						// setTimeout(() => this.another(), 500);
					}
				});
			}
		}
	},
	mounted: function () {
		this.data.forEach(item => {
			this.list.push({
				item,
				value: 0,
				sent: false
			})
		});
	}
};
