import BetsChart from '@/components/charts/components/bets/do';
import BetsChart3 from '@/components/charts/components/bets-3/do';
import {date} from '@/common/helpers';

export default {
	name: 'layout-bets',
	data: function () {
		return {
			agency: 1
		}
	},
  components: {BetsChart, BetsChart3},
	computed: {
		data: function () {
			return this.$store.getters.pdv('bets/all');
		},
		bets_data: function () {
			// [{points: [6,10.5,20,55,55,55,55,75], color: '#f00'}, {points: [6,10.5,20,null,55,55,55,55], color: '#0f0'}, {points: [6.5,11,18,40,57,50,50,50], color: '#00f'}, {points: [6,10,15,15,50,50,50,50], color: '#f0f'}]

			var list = [];

			this.$store.getters.cis('bets').forEach(betagency => {
				this.$store.getters.candidates().filter(x => x.csu.main.level === 5).forEach(person => {
					var obj = {
						points: [],
						color: person.custom.color[0].color,
						filter: betagency.hash,
						hash: person.csu.main.hash
					}

					if (this.data) {
						if (this.data.list) {
							this.$store.state.sundays.slice().reverse().forEach(sun => {
								var sundata = this.data.list.find(x => x.date === sun);
								var value = sundata.list.find(x => x.aid === betagency.id && x.hash === person.csu.main.hash);

								obj.points.push(value ? value.value : null);
							});
						}
					}

					list.push(obj);
				})
			})

			return list;
		},
		bets_legend: function () {
			var list = [];

			this.$store.getters.candidates().filter(x => x.csu.main.level === 5).forEach(item => {
				var o = {
					hash: item.csu.main.hash,
					name: item.csu.main.family,
					color: item.custom.color[0].color,
					link: '/kandidat/' + item.csu.main.hash
				}

				list.push(o);
			})

			return list;
		}
	},
  methods: {
		date,
		sorted: function (agency, day) {
			var list = [];

			this.$store.getters.candidates().forEach(cand => {
				var o = {
					cand,
					hash: cand.csu.main.hash,
					entry: this.data.list.find(x => x.date === day).list.find(x => x.aid === agency && x.hash === cand.csu.main.hash)
				}

				list.push(o);
			});

			list.sort((a, b) => (a.entry ? a.entry.value : 9999) - (b.entry ? b.entry.value : 9999));

			return list;
		}
	},
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {title: "Sázkové kurzy"});
  }
};
