import {truncate, stripURLintoDomain, date, betterURL, csuColor} from '@/common/helpers';

export default {
	name: 'EventItem',
	props: ['data', 'color', 'full', 'seek'],
	computed: {
		about: function () {
			return this.$store.getters.candidate(this.data.hash)
		},
		_color: function () {
			return this.color || (this.about ? csuColor(this.about) : '#134D8A')
		}
	},
	methods: {
		truncate, stripURLintoDomain, date, betterURL, csuColor
	}
};
