import axios from 'axios';
import {isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'admin-links',
	props: ['hash', 'phash', 'data', 'type', 'open', 'senate', 'noRemove'],
	data: function () {
		return {
			list: [],
			url: '',
			label: null,
			typeSelected: null,
			typeSocial: 0,
			sent: false,
			sending: false,
			showHints: false,
			auth: null,
			authShow: false
		}
	},
	computed: {
		valid: function () {
			return isValidHttpUrl(this.url);
		},
		typeDetected: function () {
			var type = 'link';
			// var possibleSocialLink = false;

			if (this.url.split('twitter.com').length > 1) {
				type = 'tw';
				// possibleSocialLink = true;
			}

			if (this.url.split('facebook.com').length > 1) {
				type = 'fb';
				// possibleSocialLink = true;
			}

			if (this.url.split('youtube.com').length > 1) {
				type = 'yt';
				// possibleSocialLink = true;
			}

			if (this.url.split('tiktok.com').length > 1) {
				type = 'tiktok';
				// possibleSocialLink = true;
			}

			if (this.url.split('t.me').length > 1) {
				type = 'telegram';
				// possibleSocialLink = true;
			}

			if (this.url.split('linkedin.com').length > 1) {
				type = 'linkedin';
				// possibleSocialLink = true;
			}

			if (this.url.split('instagram.com').length > 1) {
				type = 'ig';
				// possibleSocialLink = true;
			}

			if (this.url.split('wikipedia.org').length > 1) {
				type = 'wiki';
				// possibleSocialLink = true;
			}

			// console.log(type);

			if (this.type) {
				type = this.type;
			}

			// console.log(type);

			// if (['tw', 'fb', 'yt', 'ig', 'linkedin'].indexOf(type) > -1 && ['program','people','web'].indexOf(this.typeSelected) === -1) {
			// 	this.typeSelected = 'social';
			// } else if (type === 'wiki') {
			// 	this.typeSelected = 'wiki';
			// } else if (this.typeSelected) {
			// 	type = this.typeSelected;
			// }

			// console.log(type);

			if (type === 'link') {
				if (this.typeSelected === 'people') {
					type = 'people';
				} else if (this.typeSelected === 'web') {
					type = 'web';
				} else if (this.typeSelected === 'wiki') {
					type = 'wiki';
				} else {
					this.typeSelected = 'link';
				}
			}

			this.typeSelected = type;

			// console.log(type);

			return type;
		},
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		add: function () {
			if (this.valid) {
				this.sending = true;

				axios.post(this.$store.state.api + 'admin/link-add', {
					hash: this.hash,
					phash: this.phash,
					type: this.typeSelected,
					url: encodeURIComponent(this.url),
					label: this.label ? this.label.replace(/["]/g, "“").replace(/[']/g, "‘") : undefined,
					token: this.$store.state.auth
				}).then((response) => {
					this.sending = false;
					this.sent = true;

					this.list.push({
						url: this.url,
						type: this.typeSelected,
						hash: this.hash,
						phash: this.phash,
						label: this.label,
						id: response.data.id,
						removed: false
					});

					setTimeout(() => this.another(), 500);
				});
			}
		},
		remove: function (src) {
			axios.post(this.$store.state.api + 'admin/link-remove', {
				hash: this.hash,
				phash: this.phash,
				id: src.id,
				token: this.$store.state.auth
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.find(x => x.id === src.id).removed = true;
				}
			})
		},
		another: function () {
			this.sent = false;
			this.url  = '';
			this.label = '';
			this.typeSelected = 'link';
		}
	},
	mounted: function () {
		if (this.type) {
			this.typeSelected = this.type || 'link';
		}
		if (this.data) {
			this.data.forEach(src => {
				if (src.type != 'program') {
					this.list.push({
						url: src.url,
						type: src.type,
						hash: src.hash,
						phash: src.phash,
						label: src.label,
						id: src.id,
						removed: false
					});
				}
			})
		}
	}
};
