import {csuColor, links, truncate, betterURL, date, colorOfParty, beautifyNumber} from '@/common/helpers';
import PBS2 from '@/components/pbs-round-2/do';
import Bets3 from '@/components/charts/components/bets-3/do';
import BudgetBlock from '@/layout/compare/types/budget/do';

export default {
	name: 'layout-homepage',
	data: function () {
		return {
			querySearchTown: null,
			querySearchTownSearching: false
		}
	},
  components: {
		'pbs-round-2': PBS2,
		'bets-3': Bets3,
		BudgetBlock
	},
	computed: {
		loaded: function () {
			var test = true;

			// if (!this.$store.state.dynamic.source.find(x => x.source === 'obecne/okresy-flat')) test = false;
			// if (!this.$store.state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat')) test = false;
			// if (!this.$store.state.dynamic.pdv.find(x => x.source === 'party/list')) test = false;

			return test;
		},
		list: function () {
			return this.$store.getters.pdv('candidate/list');
		},
		tips: function () {
			return this.$store.getters.pdv('tip/fetch/president', 2022);
		},
		events: function () {
			return this.$store.getters.pdv('candidate/events');
		},
		media: function () {
			var d = this.$store.getters.pdv('candidate/media2');

			if (d) {
				d.data.sort((a, b) => b.updated.localeCompare(a.updated, 'cs'));
			}

			return d;
		},
		news: function () {
			return this.$store.getters.pdv('tip/news/5');
		},
		polls: function () {
			return this.$store.getters.pdv('polls/last52');
		},
			bets_all: function () {
				return this.$store.getters.pdv('bets/all');
			},
			bets_data: function () {
				// [{points: [6,10.5,20,55,55,55,55,75], color: '#f00'}, {points: [6,10.5,20,null,55,55,55,55], color: '#0f0'}, {points: [6.5,11,18,40,57,50,50,50], color: '#00f'}, {points: [6,10,15,15,50,50,50,50], color: '#f0f'}]

				var list = [];

				this.$store.getters.cis('bets').forEach(betagency => {
					this.$store.getters.candidates().filter(x => ['andrej-babis','petr-pavel'].indexOf(x.csu.main.hash) > -1).forEach(person => {
						var obj = {
							points: [],
							color: person.custom.color[0].color,
							filter: betagency.hash,
							hash: person.csu.main.hash
						}

						if (this.bets_all) {
							if (this.bets_all.list) {
								this.$store.state.sundays.slice().reverse().forEach(sun => {
									var sundata = this.bets_all.list.find(x => x.date === sun);
									var value = sundata.list.find(x => x.aid === betagency.id && x.hash === person.csu.main.hash);

									obj.points.push(value ? value.value : null);
								});
							}
						}

						list.push(obj);
					})
				})

				return list;
			},
			bets_legend: function () {
				var list = [];

				this.$store.getters.candidates().filter(x => ['andrej-babis','petr-pavel'].indexOf(x.csu.main.hash) > -1).forEach(item => {
					var o = {
						hash: item.csu.main.hash,
						name: item.csu.main.family,
						color: item.custom.color[0].color,
						link: '/kandidat/' + item.csu.main.hash
					}

					list.push(o);
				})

				return list;
			},
			rnd1: function () {
				var list = [];

				this.$store.getters.candidates().filter(x => x.csu.main.level === 5 || x.csu.main.level === 8).forEach(cand => {
					var o = {
						hash: cand.csu.main.hash,
						value: cand.csu.main.rnd1
					}

					list.push(o);
				});

				return list;
			},
			rnd2: function () {
				var list = [];

				this.$store.getters.candidates().filter(x => x.csu.main.level === 5 || x.csu.main.level === 8).forEach(cand => {
					var o = {
						hash: cand.csu.main.hash,
						value: cand.csu.main.rnd2
					}

					list.push(o);
				});

				return list;
			},
			ab: function () {
				return this.rnd2[1] ? this.rnd2[1].data : undefined
			},
			pp: function () {
				return this.rnd2[0] ? this.rnd2[0].data : undefined
			}
	},
  methods: {
		$: function (hash) {
			return this.$store.getters.party(hash)
		},
		betterURL,
		date,
		colorOfParty,
		beautifyNumber,
		links,
		truncate,
		csuColor,
		getLinkFromHash: function (item) {
			var link = "";
			var hash = Number(String(item.hash).split('-')[0]);
			var obj;

			if (hash < 100) {
				obj = this.$store.state.static.senate.list.find(x => x.id === hash);
				link = "/obvod/" + hash + '-' + betterURL(obj.display);
			} else {
				obj = this.$store.getters.town(hash);
				link = "/obec/" + hash + '-' + betterURL(obj.data[6]);
			}

			return link;
		},
		pct: function (count, base) {
			return Math.round(10000 * count / base)/100
		}
  },
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {title: "Prezidentské volby 2023"});
  }
};
