import axios from 'axios';
import {betterURL} from '@/common/helpers'

export default {
	name: 'admin',
	props:['token'],
	data: function () {
		return {
			data: null,
			code: 100,
			pass: null,
			tick: 1
		}
	},
  components: {},
	computed: {
		loaded: function () {
			return this.data && this.data.code === 200
		},
		headline: function () {
			var title = "Admin";

			if (this.data && this.data.code > 200) {
				title = 'Chyba';
			}

			this.$store.dispatch('ga', {title: 'ADMIN'});

			return title;
		}
	},
  methods: {
		betterURL,
		rePDV: function () {
			this.authme();
		},
		authme: function () {
			axios.post(this.$store.state.api + 'admin/init&c=' + new Date().getTime(), {
				token: [this.token, this.pass]
			}).then((response) => {
				this.code = response.data.code;

				if (response.data.code === 200) {
					this.data = response.data.data;
					this.$store.commit('auth', [this.token, this.pass]);
				}
			});
		}
	},
  mounted: function () {
		window.scrollTo(0, 0);
	}
};
