import {date} from '@/common/helpers';

export default {
	name: 'layout-weekly',
	data: function () {
		return {}
	},
  components: {},
	computed: {
		list: function () {
			return this.$store.getters.pdv('weekly/list');
		}
	},
  methods: {
		date
	},
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {title: "Týdeníček"});
  }
};
