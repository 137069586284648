import axios from 'axios';
import {isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'admin-support',
	props: ['hash', 'phash', 'data', 'type', 'open', 'senate'],
	data: function () {
		return {
			list: [],
			url: null,
			logo: null,
			name: null,
			color: null,
			reg: null,
			sent: false,
			sending: false,
			showHints: false,
			auth: null,
			authShow: false,
			typ: 80,
			licence: false,
			s: ['00','66','AA','FF']
		}
	},
	computed: {
		valid: function () {
			var ok = true;

			// if (this.typ === 1 && !this.reg) ok = false;
			// if (this.typ === 2 && !this.name) ok = false;
			// if (this.typ === 3 && !this.name) ok = false;

			if (!this.name || this.name.length < 2) ok = false;

			if (this.url && this.url.length > 0) {
				if (!isValidHttpUrl(this.url)) ok = false;
			}

			return ok;
		},
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		update: function (file) {
			this.logo = (this.typ === 3 ? 'https://data.programydovoleb.cz/' : '') + file.link;
		},
		add: function () {
			if (this.valid) {
				this.sending = true;

				axios.post(this.$store.state.api + 'admin/support-add', {
					hash: this.hash,
					reg: this.reg,
					type: this.typ,
					url: this.url,
					logo: this.logo,
					color: this.color,
					name: this.name ? this.name.replace(/["]/g, "“").replace(/[']/g, "‘") : undefined,
					token: this.$store.state.auth
				}).then((response) => {
					this.sending = false;
					this.sent = true;

					this.list.push({
						url: this.url,
						logo: this.logo,
						hash: this.hash,
						name: this.name,
						color: this.color,
						type: this.typ,
						reg: this.reg,
						id: response.data.id,
						removed: false
					});

					setTimeout(() => this.another(), 500);
				});
			}
		},
		remove: function (src) {
			axios.post(this.$store.state.api + 'admin/support-remove', {
				hash: this.hash,
				phash: this.phash,
				id: src.id,
				token: this.$store.state.auth
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.pop();
				}
			})
		},
		another: function () {
			this.sent = false;
			this.url  = null;
			this.name = null;
			this.reg = null;
			this.logo = null;
			this.color = null;
		}
	},
	mounted: function () {
		if (this.data) {
			this.data.forEach(src => {
				this.list.push({
					url: src.url,
					name: src.name,
					logo: src.logo,
					color: src.color,
					reg: src.reg,
					hash: src.hash,
					phash: src.phash,
					id: src.id,
					type: src.type,
					removed: false
				});
			})
		}
	}
};
