import CAbout from '../types/about/do';
import CMotto from '../types/motto/do';
import CSupport from '../types/support/do';
import CEmbeds from '../types/embeds/do';
import CLinks from '../types/links/do';
import CContacts from '../types/contacts/do';
import CPriority from '../types/priority/do';
import CValues from '../types/vals/do';
import CBudget from '../types/budget/do';
import CSocial from '../types/social/do';
import CPeople from '../types/people/do';

import {stripURLintoDomain, csuColor, betterURL, date, truncate} from '@/common/helpers';

export default {
	name: 'layout-compare',
	props: ['hash'],
	data: function () {
		return {}
	},
	components: {
		CAbout,
		CMotto,
		CSupport,
		CEmbeds,
		CLinks,
		CContacts,
		CPriority,
		CValues,
		CBudget,
		CSocial,
		CPeople
	},
	computed: {
		type: function () {
			return this.$store.state.types.find(x => x.hash === this.hash);
		},
		data: function () {
			return this.$store.getters.pdv(this.type.fetch);
		}
	},
	methods: {
		stripURLintoDomain, csuColor, betterURL, date, truncate
	},
	mounted: function () {
		window.scrollTo(0, 1);
		this.$store.dispatch("ga", {title: this.type.title});
	},
	watch: {
		hash: function () {
			window.scrollTo(0, 1);
			this.$store.dispatch("ga", {title: this.type.title});
		}
	}
};
