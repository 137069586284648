import axios from 'axios';

export default {
	name: 'admin-motto',
	props: ['hash', 'phash', 'data', 'open'],
	data: function () {
		return {
			list: [],
			motto: null,
			sent: false,
			sending: false,
			showHints: false,
			auth: null,
			authShow: false
		}
	},
	computed: {
		valid: function () {
			return this.motto && this.motto.length > 4 && this.motto.length < 128;
		},
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		add: function () {
			if (this.valid) {
				this.sending = true;

				axios.post(this.$store.state.api + 'admin/motto-add', {
					hash: this.hash,
					phash: this.phash,
					motto: this.motto ? this.motto.replace(/["]/g, "“").replace(/[']/g, "‘") : undefined,
					token: this.$store.state.auth
				}).then((response) => {
					this.sending = false;
					this.sent = true;

					this.list.push({
						hash: this.hash,
						phash: this.phash,
						motto: this.motto,
						id: response.data.id,
						removed: false
					});

					setTimeout(() => this.another(), 500);
				});
			}
		},
		remove: function (src) {
			axios.post(this.$store.state.api + 'admin/motto-remove', {
				hash: this.hash,
				phash: this.phash,
				id: src.id,
				token: this.$store.state.auth
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.find(x => x.id === src.id).removed = true;
				}
			})
		},
		another: function () {
			this.sent = false;
			this.motto = '';
		}
	},
	mounted: function () {
		if (this.type) {
			this.typeSelected = this.type || 'link';
		}
		if (this.data) {
			this.data.forEach(src => {
				this.list.push({
					hash: src.hash,
					phash: src.phash,
					motto: src.motto,
					id: src.id,
					removed: false
				});
			})
		}
	}
};
