import axios from 'axios';

/* eslint-disable */

export default {
	name: 'update-form',
	props: ['part', 'hint', 'compact', 'hash'],
	components: {},
	data: function () {
		return {
			url: '',
			note: '',
			name: '',
			contact: '',
			sent: false,
			sending: false,
			response: ''
		}
	},
	computed: {
		valid: function () {
			return this.note.length > 0;
		}
	},
	methods: {
		send: function () {
			if (this.valid) {
				this.sending = true;

				axios.post(this.$store.state.api + 'report/add', {
					page: this.$route.path,
					part: this.part,
					content: encodeURIComponent(this.note),
					name: encodeURIComponent((this.hash ? this.hash + ': ' : '') + this.name),
					contact: encodeURIComponent(this.contact),
					// }
				}).then(response => {
					this.sending = false;
					this.sent = true;
					this.response = response;
				});
			}
		},
		another: function () {
			this.sent = false;
			this.url  = '';
			this.note = '';
			this.name = '';
		}
	},
	mounted: function () {
		this.note = '';
		this.url  = '';
		this.name = '';
	}
};
