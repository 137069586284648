import Vue from 'vue';

import ReportBlock from './report-block/do';
import TruncatedText from './truncated-text/do';
import ScrollableTable from './scrollable-table/do';
import TipForm from './tip-form/do';
import NewsItem from './news-item/do';
import MediaItem from './media-item/do';
import SenateListItem from './senate-list-item/do';
import SenateGridItem from './senate-grid-item/do';
import SenateTinyItem from './senate-tiny-item/do';
import SenateResultItem from './senate-result-item/do';
import SenateAside from './senate-aside/do';
import ImageUpload from './image-upload/do';
import RoundButton from './round-button/do';
import LinkAdd from './link-add/do';
import ContactAdd from './contact-add/do';
import VoteElement from './vote-element/do';
import VoteElementMultiple from './vote-element-multiple/do';
import VoteGroup from './vote-group/do';
import RequestAccess from './request-access/do';
import QuoteArrow from './quote-arrow/do';
import QuoteBlock from './quote-block/do';
import ViewSwitch from './view-switch/do';
import GridOrList from './grid-or-list/do';
import AnswerBlock from './answer-block/do';
import FaqBlock from './faq-block/do';
import PollsEntry from './polls-entry/do';
import SearchParty from './search-party/do';
import EventItem from './event-item/do';
import HistoryItem from './history-item/do';
import PersonHeader from './person-header/do';
import PersonFooter from './person-footer/do';
import SearchTown from './search-town/do';

Vue.component('report-block', ReportBlock);
Vue.component('truncated-text', TruncatedText);
Vue.component('scrollable-table', ScrollableTable);
Vue.component('tip-form', TipForm);
Vue.component('news-item', NewsItem);
Vue.component('media-item', MediaItem);
Vue.component('senate-list-item', SenateListItem);
Vue.component('senate-grid-item', SenateGridItem);
Vue.component('senate-tiny-item', SenateTinyItem);
Vue.component('senate-result-item', SenateResultItem);
Vue.component('senate-aside', SenateAside);
Vue.component('image-upload', ImageUpload);
Vue.component('round-button', RoundButton);
Vue.component('link-add', LinkAdd);
Vue.component('contact-add', ContactAdd);
Vue.component('vote-element', VoteElement);
Vue.component('vote-element-multiple', VoteElementMultiple);
Vue.component('vote-group', VoteGroup);
Vue.component('request-access', RequestAccess);
Vue.component('quote-arrow', QuoteArrow);
Vue.component('quote-block', QuoteBlock);
Vue.component('view-switch', ViewSwitch);
Vue.component('grid-or-list', GridOrList);
Vue.component('answer-block', AnswerBlock);
Vue.component('faq-block', FaqBlock);
Vue.component('polls-entry', PollsEntry);
Vue.component('search-party', SearchParty);
Vue.component('event-item', EventItem);
Vue.component('history-item', HistoryItem);
Vue.component('person-header', PersonHeader);
Vue.component('person-footer', PersonFooter);
Vue.component('search-town', SearchTown);
