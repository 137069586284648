import {truncate, stripURLintoDomain, date, csuColor} from '@/common/helpers';

export default {
	name: 'MediaItem',
	props: ['data'],
	methods: {
		truncate, stripURLintoDomain, date, csuColor
	},
	computed: {
		candidate: function () {
			return this.$store.getters.candidate(this.data.hash);
		},
		color: function () {
			return this.candidate ? csuColor(this.candidate) : '#ccc';
		}
	}
};
