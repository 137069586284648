import {betterURL, truncate, date, beautifyNumber} from '@/common/helpers';

export default {
	name: 'CompareValues',
	props: ['list', 'color', 'type', 'person'],
	methods: {
		betterURL, truncate, date, beautifyNumber
	},
	computed: {
		candidates: function () {
			var list = [];
				this.$store.getters.candidates().filter(x => x.csu.main.no).forEach(person => {
					if (this.list.find(x => x.hash === person.csu.main.hash)) {
						list.push(person);
					}
				})
			return list;
		}
	}
};
