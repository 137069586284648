import {truncate, links, csuColor, date} from '@/common/helpers';

export default {
	name: 'senate-aside',
	props: ['hash'],
	data: function () {
		return {
			menu: [

			],
			agencies: [
				'Median', 'Kantar', 'STEM/MARK', 'Ipsos', 'CVVM', 'Stem'
			]
		}
	},
	computed: {
		color: function () {
			return csuColor(this.core);
		},
		links: function () {
			return links(this.core.custom.links);
		},
		core: function () {
			var loaded = this.$store.getters.pdv('candidate/list');

			if (loaded) {
				loaded = loaded.list.find(x => x.csu.main.hash === this.hash);
			}

			return loaded;
		},
		data: function () {
			var loaded = this.$store.getters.pdv('candidate/fetch/' + this.hash);

			this.menu = [];

			if (loaded) {
				if (loaded.data.custom.answers.length > 0) this.menu.push(['/kandidat/' + this.hash + '/odpovedi-na-volebni-otazky', 'Odpovědi', loaded.data.custom.answers.length, '#fecc03']);
				this.menu.push(['/kandidat/' + this.hash + '/profil', 'Profil']);
				if (loaded.data.custom.priority.length > 0) this.menu.push(['/kandidat/' + this.hash + '/program-a-priority', 'Priority, program', loaded.data.custom.priority.length]);
				if (loaded.data.custom.events.length > 0) this.menu.push(['/kandidat/' + this.hash + '/udalosti-a-setkani', 'Kalendář akcí', loaded.data.custom.events.filter(x => new Date(x.date).getTime() >= new Date(new Date().toISOString().slice(0,10)).getTime()).length]);
				if (loaded.data.custom.media.length > 0) this.menu.push(['/kandidat/' + this.hash + '/clanky-a-rozhovory', 'Rozhovory', loaded.data.custom.media.length]);
				if (loaded.data.custom.team.length + loaded.data.custom.advisors.length + loaded.data.custom.office.length > 0) this.menu.push(['/kandidat/' + this.hash + '/poradci-a-tym', 'Poradci a tým', loaded.data.custom.advisors.length + loaded.data.custom.office.length + loaded.data.custom.team.length]);
				if (loaded.data.custom.ads.length > 0) this.menu.push(['/kandidat/' + this.hash + '/volebni-materialy', 'Materiály', loaded.data.custom.ads.length]);
					if (loaded.data.custom.gallery.length > 0) this.menu.push(['/kandidat/' + this.hash + '/fotogalerie', 'Fotografie', loaded.data.custom.gallery.length]);
				this.menu.push(['/kandidat/' + this.hash + '/pruzkumy-sazky-odhady-popularita', 'Průzkumy, sázky', '']);
				if (loaded.data.custom.topic_external.length > 0) this.menu.push(['/kandidat/' + this.hash + '/otazky-a-temata', 'Anketní otázky', loaded.data.custom.topic_external.length]);
			}

			return loaded ? loaded.data.custom : null;
		}
	},
	methods: {
		csuColor, truncate, date
	}
};
