import axios from 'axios';

export default {
	name: 'root-budget',
	props: ['hash', 'data'],
	data: function () {
		return {
			form: {
				source: null,
				account: null
			},
			sending: false,
			sent: false
		}
	},
	computed: {
		valid: function () {
			return true;
		}
	},
	methods: {
		add: function () {
			if (this.valid) {
				this.sending = true;

				var obj = {
					token: this.$store.state.auth,
					hash: this.hash
				}

				Object.keys(this.form).forEach(key => {
					obj[key] = this.form[key];
				});

				axios.post(this.$store.state.api + 'root/budget-add', obj).then((response) => {
					this.sending = false;

					if (response.data.code === 200) {
						this.sent = true;
						setTimeout(() => this.another(), 500);
					}
				});
			}
		},
		another: function () {
			this.form.source = null;
			this.form.account = null;
			this.form.funds = null;
			this.form.income = null;
			this.form.spending = null;
		}
	},
	mounted: function () {
		this.another();

		if (this.data[0]) {
			this.form.source = this.data[0].source;
			this.form.account = this.data[0].account;
		}
	}
};
