import axios from 'axios';

export default {
	name: 'admin-password',
	props: ['hash', 'admin', 'token'],
	data: function () {
		return {
			pass: '',
			sent: false,
			sending: false,
			id: null
		}
	},
	computed: {
		valid: function () {
			return this.pass.length > 7;
		}
	},
	methods: {
		add: function () {
			if (this.valid) {
				this.sending = true;

				axios.post(this.$store.state.api + 'admin/password-update&c=' + new Date().getTime(), {
					hash: this.hash,
					pass: this.pass,
					token: this.$store.state.auth
				}).then((response) => {
					this.sending = false;
					this.sent = true;

					if (response.data.id) {
						this.id = response.data.id;
					}

					setTimeout(() => this.another(), 500);
				});
			}
		},
		another: function () {
			this.sent = false;

			if (!this.admin) {
				this.$store.commit('auth', [this.token, this.pass]);
			}

			this.pass  = '';
		}
	}
};
