import {betterURL} from '@/common/helpers';

export default {
	name: 'search-town',
	components: {},
	props: ['placeholder', 'tipsPrimator', 'cb'],
	data: function () {
		return {
			querySearchTown: null,
			querySearchTownSearching: false
		}
	},
	computed: {
		loaded: function () {
			var test = true;

			// if (!this.$store.state.dynamic.source.find(x => x.source === 'obecne/okresy-flat')) test = false;
			// if (!this.$store.state.dynamic.source.find(x => x.source === 'volby/kom/2022/static/obce-flat')) test = false;
			// if (!this.$store.state.dynamic.pdv.find(x => x.source === 'party/list')) test = false;

			return test;
		},
		towns: function () {
			return this.$store.getters.getSource('volby/kom/2022/static/obce-flat');
		},
		districts: function () {
			return this.$store.getters.getSource('obecne/okresy-flat');
		},
		querySearchTownResults: function () {
			if (!this.querySearchTown || this.loaded === false || !this.towns) return null;
			if (this.querySearchTown.length < 2) return null;

			var hash = betterURL(this.querySearchTown);
			var results = [];

			if (hash.length === 2 && (hash != 'es' && hash != 'as' )) return results;

			this.querySearchTownSearching = true;

			this.$nextTick();

			if (hash.length === 2) {
				var c;

				if (hash === 'as') c = this.find(554499);
				if (hash === 'es') c = this.find(561754);

				if (c) {
					var o = {};
					o.num = c.data[0];
					o.display = c.data[6];
					o.link = '/obec/' + o.num + '-' + betterURL(o.display);
					o.okres = c.district;

					results.push(o);
				}
			}

			if (hash.length > 2) {
				var townlist = this.towns.list;

				Object.keys(townlist).forEach(key => {
					// console.log(key);
					townlist[key].filter(x => betterURL(x[6]).split(hash).length > 1).forEach(item => {
						var o = {};
						o.num = item[0];
						o.display = item[6];
						o.link = '/obec/' + o.num + '-' + betterURL(o.display);
						o.okres = this.findDistrict(key);

						if (item[7]) {
							o.part = this.find(item[7]);
						}

						results.push(o);
					});
				});

			}

			results.sort((a, b) => a.display.localeCompare(b.display, 'cs'));

			this.$nextTick();

			this.querySearchTownSearching = false;

			return results;
		},
	},
	methods: {
		select: function (item) {
			this.$emit('update', item);
			this.querySearchTown = null;
		},
		find: function (id) {
			var lookup;

			Object.keys(this.towns.list).forEach(key => {
				var data = this.towns.list[key].find(x => x[0] === id);

				if (data) {
					lookup = {
						hash: data[0] + '-' + betterURL(data[6]),
						district: this.findDistrict(key),
						data
					};

					if (data[7]) {
						lookup.part = this.find(data[7])
					}
				}
			});

			return lookup;
		},
		findDistrict: function (id) {
			return this.districts.list.find(x => x.nuts === id)
		}
	},
	mounted: function () {}
};
