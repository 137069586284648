import {links, stripURLintoDomain, csuColor, joinWithLast, betterURL, date, beautifyDate, beautifyNumber, colorOfParty, gradient, getLinkType, isValidHttpUrl} from '@/common/helpers';

export default {
	name: 'layout-candidate',
	props: ['hash'],
	data: function () {
		return {}
	},
	computed: {
		loaded: function () {
			var test = true;

			return test;
		},
		color: function () {
			return csuColor(this.core);
		},
		links: function () {
			return links(this.core.custom.links);
		},
		core: function () {
			var loaded = this.$store.getters.pdv('candidate/list');

			if (loaded) {
				loaded = loaded.list.find(x => x.csu.main.hash === this.hash);

								this.$store.dispatch('ga', {title: loaded.csu.main.name + ' ' + loaded.csu.main.family + ', události a setkání kandidát' + (loaded.csu.main.sex === 2 ? 'ky' : 'a') + ' na prezidenta České republiky'});

								setTimeout(() => {
									if (location.hash.length > 1) this.$refs.anchors.trigger(location.hash);
								}, 1500);
			}

			return loaded;
		},
		data: function () {
			var loaded = this.$store.getters.pdv('candidate/fetch/' + this.hash);

			return loaded ? loaded.data.custom : null;
		},
		events: function () {
			var list = [];
			var past = [];

			if (this.data) {
				this.data.events.forEach(event => {
					if (new Date(event.date).getTime() >= new Date(new Date().toISOString().slice(0,10)).getTime()) {
						list.push(event);
					} else {
						past.push(event);
					}
				})
			}

			past.sort((a, b) => b.date.localeCompare(a.date, 'cs'));
			list.sort((a, b) => (a.start || "24:00:00").localeCompare((b.start || "24:00:00"), 'cs'));
			list.sort((a, b) => a.date.localeCompare(b.date, 'cs'));

			return {list, past};
		}
	},
  methods: {
		$: function (hash) {
			return this.$store.getters.party(hash)
		},
		joinWithLast, betterURL, beautifyNumber, date, gradient, getLinkType, beautifyDate, colorOfParty, isValidHttpUrl, csuColor,
		domain: function (url) {
			return stripURLintoDomain(url).split('/')[0]
		},
		mediaicon: function (url) {
			var outlets = ['blesk', 'denik', 'drbna', 'hn', 'idnes', 'irozhlas', 'lidovky', 'novinky', 'seznamzpravy'];
			var link = this.$store.state.root + 'static/icon/link.svg';

			outlets.forEach(o => {
				if (url.split(o).length > 1) link = this.$store.state.root + 'static/icon-media/' + o + '.jpg';
			})

			return link;
		}
  },
  mounted: function () {
    window.scrollTo(0, 1);
  },
	watch: {
		hash: function () {
			window.scrollTo(0, 1);
		},
		$route: function () {
			window.scrollTo(0, 1);
		}
	}
};
