import {date} from '@/common/helpers';

export default {
  data: function () {
    return {
      width: 0,
      height: 280,
      gap: 0,
      vgap: 240,
      offset: {
        t: 20,
        b: 5,
        l: 10,
        r: 0
      },
      filter: [],
      selected: null,
      tick: 0
    }
  },
  props: ['data', 'hasnull', 'preselected', 'legend'],
  computed: {
    sundays: function () {
      var list = [];
      this.$store.state.sundays.forEach(s => list.push(s));
      list.reverse();

      return list
    },
    lines: function () {
      var list = [];

      var dataset = this.data || [{points: [6,10.5,20,55,55,55,55,75], color: '#f00'}, {points: [6,10.5,20,null,55,55,55,55], color: '#0f0'}, {points: [6.5,11,18,40,57,50,50,50], color: '#00f'}, {points: [6,10,15,15,50,50,50,50], color: '#f0f'}]

      dataset.forEach(line => {
        var path = [];
        var points = [];
        var previousExists = false;
        var priority = 1;

        if (this.selected) {
          priority = 2;

          if (line.filter === this.selected) {
            priority = 1;
          }
        }

        line.points.forEach((point, index) => {
          var p;
          if (typeof point === 'number') {

            var x = this.offset.l + index * this.gap;
            var y = this.offset.t;

            // if (point <= 10) {
              y += (point - 1) / 10 * this.vgap;
            // } else if (point <= 100) {
              // y += point / 100 * this.vgap + this.vgap;
            // } else {
              // y += point / 1000 * this.vgap + this.vgap * 2;
            // }

            points.push([x, y]);

            p = (previousExists ? "L " : "M ") + x + ' ' + y + ' ' ;

            previousExists = true;
          } else {
            points.push(null);

            p = "M 0 0";
            previousExists = false;
          }

          path.push(p);
        });

        list.push({tick: this.tick, priority, path: path.join(' '), points, color: priority === 1 ? line.color : '#00000011'});
      });

      // var test = ["M " + this.offset.l +  " " + this.offset.t +  " L " + (this.offset.t + 100) + " 100"];
      // list.push({path: test.join(','), color: '#f00'});

      list.sort((a, b) => a.priority - b.priority);

      return list;
    }
  },
  methods: {
    date,
    svgwidth: function () {
      this.width = this.$el.querySelector('.bets-chart---area').getBoundingClientRect().width;
      this.gap = Math.round((this.width - this.offset.l - this.offset.r) / this.$store.state.sundays.length);
    },
    latest: function (list) {

      var value = null;

      for (var i = list.length - 1; i > 0; i--) {
        if (value === null && list[i] != null) value = list[i];
      }

      return value;
    }
  },
  mounted: function () {
    this.svgwidth();

    if (this.data) {
      this.data.forEach(item => {
        if (item.filter) {
          if (item.filter) {
            if (!this.filter.find(x => x.hash === item.filter)) this.filter.push({hash: item.filter, enabled: false})
          }
        }
      })
    }

    if (this.preselected) {
      this.selected = this.preselected;
    }

    window.addEventListener('resize', () => this.svgwidth());
    setInterval(() => this.tick++, 1000);
  }
}
