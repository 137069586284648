import Vue from 'vue';

import CollapsibleElement from './collapsible-element/do';
import IconElement from './icon-element/do';
import LabelWithDot from './label-with-dot/do';
import LazyLoad from './lazy-load/do';
import Loader from './loader/do';
import LogoItem from './logo-item/do';
import MainFooter from './footer/do';
import MainHeader from './header/do';
import ModalElement from './modal-element/do';
import OutboundLink from './outbound-link/do';
import OutboundIcon from './outbound-icon/do';
import PartyLink from './party-link/do';
import PartyPolist from './party-polist/do';
import PersonIcon from './person-icon/do';
import PersonFace from './person-face/do';
import PersonName from './person-name/do';
import PersonPhoto from './person-photo/do';
import ResponsiveLabel from './responsive-label/do';
import ShareBlock from './share-block/do';
import WidgetBlock from './widget-block/do';
import UpdateForm from './update-form/do';
import ReportForm from './report-form/do';

Vue.component('collapsible-element', CollapsibleElement);
Vue.component('icon-element', IconElement);
Vue.component('label-with-dot', LabelWithDot);
Vue.component('lazy-load', LazyLoad);
Vue.component('loader-element', Loader);
Vue.component('logo-item', LogoItem);
Vue.component('main-footer', MainFooter);
Vue.component('main-header', MainHeader);
Vue.component('modal-element', ModalElement);
Vue.component('outbound-link', OutboundLink);
Vue.component('outbound-icon', OutboundIcon);
Vue.component('party-link', PartyLink);
Vue.component('party-polist', PartyPolist);
Vue.component('person-icon', PersonIcon);
Vue.component('person-name', PersonName);
Vue.component('person-face', PersonFace);
Vue.component('person-photo', PersonPhoto);
Vue.component('responsive-label', ResponsiveLabel);
Vue.component('share-block', ShareBlock);
Vue.component('widget-block', WidgetBlock);
Vue.component('update-form', UpdateForm);
Vue.component('report-form', ReportForm);
