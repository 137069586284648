import {date, csuColor, truncate} from "@/common/helpers";
import ResultsGraph from "@/components/results-graph/do";
import ResultsGraphPeople from "@/components/results/graphs/graph-people/do";
import ResultsGraphDuel from "@/components/results/graphs/graph-duel/do";

export default {
	name: 'PollsEntry',
	props: ['poll', 'entries', 'hash', 'open', 'label', 'highlight', 'related'],
	data: function () {
		return {
			show: false
		}
	},
	components: {
		ResultsGraph,
		ResultsGraphPeople,
		ResultsGraphDuel
	},
	methods: {
		date, truncate,
		toggleShow: function () {
			this.show = !this.show;
		}
	},
	computed: {
		color: function () {
			return '#000';
		},
		// parties: function () {
		// 	// return this.$store.getters.pdv('party/list')
		// },
		data: function () {
			if (!this.entries || !this.poll) return undefined;

			var list = [];
			var about = {};

			var p = this.poll;

			about.agency = p.agency;
			about.date = p.published;
			about.meta = p.meta;
			about.source = p.source;
			about.comment = p.comment;

			var match = undefined;

			this.entries.forEach(item => {
				var obj = {
					item,
					person: this.$store.getters.candidate(item.hash)
				};

				obj.short = obj.person.csu.main.family;
				obj.initials = obj.person.csu.main.name.charAt(0) + obj.person.csu.main.family.charAt(0);
				obj.pct = item.value;
				obj.sub = item.sub;
				obj.hash = item.hash;

				if (this.highlight) {
					obj.color = '#ccc';
					if (item.hash === this.highlight) {
						obj.color = csuColor(obj.person);
					}
				} else {
					obj.color = csuColor(obj.person);
				}

				if (this.related) {
					var o = this.related.find(x => x.hash === item.hash && x.sub === item.sub);

					if (o) {
						obj.previous = o.value;
					}
				}

				obj.photo = obj.person.custom.photo.length > 0 ? obj.person.custom.photo[0].url : null;
				obj.link = '/kandidat/' + obj.person.csu.main.hash;

				list.push(obj);
			});

			list.sort((a, b) => b.pct - a.pct);

			if (this.poll.type === 3 && this.highlight) {
				match = [];

				list.filter(x => x.hash === this.highlight).forEach(item => {
					match.push([item, list.find(x => x.sub === item.sub && x.hash != item.hash)]);
				});

				match.sort((a, b) => a[0].short.localeCompare(b[0].short, 'cs'));
			}

			if (this.poll.type === 3 && !this.highlight) {
				match = [];
				var used = [];

				list.forEach(item => {

					if (used.indexOf(item.sub) === -1) {
						used.push(item.sub);
						match.push(list.filter(x => x.sub === item.sub));
					}
				});

				match.sort((a, b) => a[0].short.localeCompare(b[0].short, 'cs'));
			}

			return {list, about, match};
		}
	},
	mounted: function () {
		if (this.open) this.show = true;
	}
};
