export default {
	name: 'layout-how-to',
	data: function () {
		return {
			results: {
				jan9: [
					{q: "Koho budete volit v prvním kole?", a: 3675, t: true, r: [['petr-pavel', 45.79],['danuse-nerudova', 27.5],['andrej-babis',11.92],['pavel-fischer',6.12]]},
					{q: "Kdybyste nemohli volit svého preferovaného kanidáta, koho byste volili místo něho/ní?", a: 1875, t:true, r: [['danuse-nerudova', 33.92],['petr-pavel', 33.76],['pavel-fischer',9.55],['marek-hilser',8.05]]},
					{q: "Jaké téma je pro vás nejdůležitější?", a: 1750, t:false, r: [['západní směřování země', 22.34],['bezpečnost', 17.06],['rovnost práv',16.54],['inflace',14.19]]},
					{q: "Co považujete u kandidáta za nejdůležitější?", a: 1675, t:false, r: [['morální zásady', 39.11],['schopnost komunikovat', 18],['rozhled',12.08],['politické zkušenosti',9.39]]},
					{q: "Co vás u kandidátů nejvíc trápí?", a: 1625, t:false, r: [['trestní stíhání', 33.23],['nevyjasněné kauzy', 14.91],['vztah k Rusku',11.73],['příliš konzervativní postoje',11.42]]},
					{q: "Kdo skončí třetí?", a: 1575, t:true, r: [['andrej-babis', 41.56],['danuse-nerudova', 39.97],['petr-pavel',10.21],['pavel-fischer',4.6]]},
					{q: "Kolik hlasů bude třetímu v pořadí chybět na postup?", a: 1500, t:false, r: [['do 50 000 hlasů', 44.73],['do 10 000 hlasů', 33.31],['do 100 000 hlasů',14.95]]},
					{q: "V roce 2018 byla účast v prvním kole 62 %. Jaká bude tentokrát?", a: 1575, t:false, r: [['vyšší jak 64 %', 40.29],['62 až 64 %', 39.78],['60 až 62 %', 11.3]]},
					{q: "Co podle vás nejvíc ovlivní první kolo?", a: 1550, t:false, r: [['účast mladých', 43.94],['výkony v debatách', 24.63],['nečekaná kauza', 7.95],['rozsudek v soudním jednání', 7.38],['vyšší zisky podceňovaných kandidátů', 7.06],['volební program', 6.35]]},
					{q: "Kdo se nakonec stane prezidentem?", a: 1575, t: true, r: [['petr-pavel', 61.22],['danuse-nerudova', 27.42],['andrej-babis',7.02]]}
				],
				jan14: [
					{q: "Koho budete volit v prvním kole?", a: 4900, t: true, r: [['petr-pavel', 44.34],['danuse-nerudova', 27.16],['andrej-babis',10.60],['pavel-fischer',8.12]]},
					{q: "Kdybyste nemohli volit svého preferovaného kanidáta, koho byste volili místo něho/ní?", a: 2850, t:true, r: [['danuse-nerudova', 34.14],['petr-pavel', 32.98],['pavel-fischer',10.35],['marek-hilser',7.32]]},
					{q: "Jaké téma je pro vás nejdůležitější?", a: 2650, t:false, r: [['západní směřování země', 20.98],['bezpečnost', 18.19],['rovnost práv',16.53],['inflace',15.48]]},
					{q: "Co považujete u kandidáta za nejdůležitější?", a: 2550, t:false, r: [['morální zásady', 38.92],['schopnost komunikovat', 18.1],['rozhled',13.05],['politické zkušenosti',9.86]]},
					{q: "Co vás u kandidátů nejvíc trápí?", a: 2500, t:false, r: [['trestní stíhání', 31.96],['nevyjasněné kauzy', 15.4],['vztah k Rusku',11.87],['příliš konzervativní postoje',10.67]]},
					{q: "Kdo skončí třetí?", a: 2400, t:true, r: [['andrej-babis', 45.5],['danuse-nerudova', 37.28],['petr-pavel',8.94],['pavel-fischer',4.92]]},
					{q: "Kolik hlasů bude třetímu v pořadí chybět na postup?", a: 2275, t:false, r: [['do 50 000 hlasů', 43.13],['do 10 000 hlasů', 32.64],['do 100 000 hlasů',15.95]]},
					{q: "V roce 2018 byla účast v prvním kole 62 %. Jaká bude tentokrát?", a: 2375, t:false, r: [['vyšší jak 64 %', 41.5],['62 až 64 %', 38.38],['60 až 62 %', 11.18]]},
					{q: "Co podle vás nejvíc ovlivní první kolo?", a: 2350, t:false, r: [['účast mladých', 41.89],['výkony v debatách', 25.81],['rozsudek v soudním jednání', 8.74],['nečekaná kauza', 7.3],['vyšší zisky podceňovaných kandidátů', 7.22],['volební program', 6.2]]},
					{q: "Kdo se nakonec stane prezidentem?", a: 2375, t: true, r: [['petr-pavel', 63.39],['danuse-nerudova', 23.18],['andrej-babis',8.74]]}
				]
			}
		}
	},
  components: {},
	computed: {
		list: function () {
			return this.$store.getters.pdv('candidate/list');
		},
	},
  methods: {
	},
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {title: "Anketa"});
  }
};
