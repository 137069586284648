import {links, csuColor} from "@/common/helpers";

export default {
	name: 'PersonHeader',
	props: ['data', 'hash', 'subheadline'],
	computed: {
		color: function () {
			return csuColor(this.data);
		},
		links: function () {
			return links(this.data.custom.links);
		}
	}
};
