import axios from 'axios';
import {date as bdate} from '@/common/helpers';

export default {
	name: 'admin-events',
	props: ['hash', 'phash', 'data', 'open', 'root'],
	data: function () {
		return {
			location: null,
			date: null,
			start: null,
			end: null,
			title: null,
			content: null,
			organiser: null,
			moderator: null,
			recording: null,
			image: null,
			source: null,
			relates: [],
			list: [],
			id: null
		}
	},
	computed: {
		src: function () {
			if (this.url) {
				return this.url;
			} else {
				return '/static/missing.png';
			}
		},
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		bdate,
		update_photo: function (photo, member) {
			var self = member || this;

			self.image = this.$store.state.server + photo.link;
		},
		click_edit: function (item, state) {
			item.edit = state;
			item.tick++;
			this.tick++;
		},
		click_add: function () {
			axios.post(this.$store.state.api + 'admin/event-add', {
				hash: this.hash,
				location: this.location,
				date: this.date,
				start: this.start,
				end: this.end,
				title: this.title,
				content: this.content,
				organiser: this.organiser,
				moderator: this.moderator,
				recording: this.recording,
				relates: this.root ? this.relates.join(',') : undefined,
				image: this.image,
				source: this.source,
				token: this.$store.state.auth
			}).then((response) => {
				if (response.data.code === 200) {
					this.tick++;

					var obj = {
						location: this.location,
						date: this.date,
						start: this.start,
						end: this.end,
						title: this.title,
						content: this.content,
						organiser: this.organiser,
						moderator: this.moderator,
						recording: this.recording,
						image: this.image,
						source: this.source,
						relates: this.relates,
						id: response.data.id,
						removed: false,
						edit: false,
						tick: 0
					};

					this.list.push(obj);


						this.location = null;
						this.date = null;
						this.start = null;
						this.end = null;
						this.title = null;
						this.content = null;
						this.organiser = null;
						this.moderator = null;
						this.recording = null;
						this.relates = [];
						this.image = null;
						this.source = null;
				}
			});
		},
		click_update: function (item) {
			axios.post(this.$store.state.api + 'admin/event-update', {
				id: item.id,
				hash: this.hash,
				location: item.location,
				date: item.date,
				start: item.start,
				end: item.end,
				title: item.title,
				content: item.content,
				organiser: item.organiser,
				moderator: item.moderator,
				recording: item.recording,
				relates: item.relates,
				image: item.image,
				source: item.source,
				token: this.$store.state.auth
			}).then(() => {
				// item.edit = true;
				item.tick++;
				this.tick++;
			});
		},
		click_remove: function (item) {
			axios.post(this.$store.state.api + 'admin/event-remove', {
				id: item.id,
				hash: this.hash,
				token: this.$store.state.auth
			}).then(() => {
				item.removed = true;
				item.tick++;
				this.tick++;
			});
		},
		click_relates: function (hash) {
			var index = this.relates.indexOf(hash);

			if (index > -1) {
				this.relates.splice(index, 1)
			} else {
				this.relates.push(hash);
			}
		},
		list_add: function (item) {
			var obj = {
				hash: item.hash,
				location: item.location,
				date: item.date,
				start: item.start,
				end: item.end,
				title: item.title,
				content: item.content,
				relates: item.relates,
				organiser: item.organiser,
				moderator: item.moderator,
				recording: item.recording,
				image: item.image,
				source: item.source,
				id: item.id,
				removed: false,
				edit: false,
				tick: 0
			};

			this.list.push(obj);
		}
	},
	mounted: function () {
		this.data.forEach(item => {
			this.list_add(item);
		});

		if (this.root) {
			this.root.forEach(person => {
				person.custom.events.forEach(item => {
					if (!this.list.find(x => x.id === item.id) && item.hash === 'generic') {
						this.list_add(item);
					}
				});
			});
		}
	}
};
