import {betterURL, truncate} from '@/common/helpers';

export default {
	name: 'layout-questions-list',
	methods: {
		betterURL, truncate
	},
  mounted: function () {
    window.scrollTo(0, 1);
    this.$store.dispatch("ga", {title: "Volební otázky"});
  }
};
