import {links} from '@/common/helpers';

export default {
	name: 'CompareLinks',
	props: ['list', 'color', 'type', 'person'],
	computed: {
		links: function () {
			return links(this.list)
		}
	}
};
