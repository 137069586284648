import axios from 'axios';

export default {
	name: 'admin-values',
	props: ['hash', 'phash', 'data', 'open'],
	data: function () {
		return {
			list: [],
			headline: null,
			content: null,
			sent: false,
			sending: false,
			showHints: false,
			auth: null,
			authShow: false
		}
	},
	computed: {
		valid: function () {
			return (!this.headline || (this.headline.length > 3 && this.headline.length < 128)) && (!this.content || this.content.length > 3) && (this.content || this.headline);
		},
		token: function () {
			return this.$route.path.split('/').pop();
		}
	},
	methods: {
		add: function () {
			if (this.valid) {
				this.sending = true;

				axios.post(this.$store.state.api + 'admin/values-add', {
					hash: this.hash,
					phash: this.phash,
					headline: this.headline ? this.headline.replace(/["]/g, "“").replace(/[']/g, "‘") : undefined,
					content: this.content ? this.content.replace(/["]/g, "“").replace(/[']/g, "‘") : undefined,
					token: this.$store.state.auth
				}).then((response) => {
					this.sending = false;
					this.sent = true;

					this.list.push({
						hash: this.hash,
						phash: this.phash,
						headline: this.headline,
						content: this.content,
						id: response.data.id,
						removed: false,
						edited: false
					});

					setTimeout(() => this.another(), 500);
				});
			}
		},
		restore: function (src) {
			src.headline = src.headlineOriginal;
			src.content = src.contentOriginal;
			src.edited = false;
		},
		update: function (src) {
			axios.post(this.$store.state.api + 'admin/values-update', {
				headline: src.headline,
				content: src.content,
				id: src.id,
				hash: this.hash,
				token: this.$store.state.auth
			}).then((response) => {
				if (response.data.code === 200) {
					src.headlineOriginal = src.headline;
					src.contentOriginal = src.content;
					src.edited = false;
				}
			})
		},
		remove: function (src) {
			axios.post(this.$store.state.api + 'admin/values-remove', {
				hash: this.hash,
				phash: this.phash,
				id: src.id,
				token: this.$store.state.auth
			}).then((response) => {
				if (response.data.code === 200) {
					this.list.find(x => x.id === src.id).removed = true;
				}
			})
		},
		another: function () {
			this.sent = false;
			this.headline = null;
			this.content = null;
		}
	},
	mounted: function () {
		if (this.data) {
			this.data.forEach(src => {
				this.list.push({
					hash: src.hash,
					phash: src.phash,
					headline: src.headline,
					content: src.content,
					headlineOriginal: src.headline,
					contentOriginal: src.content,
					id: src.id,
					removed: false,
					edited: false
				});
			})
		}
	}
};
